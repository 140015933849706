<script>
export default {
    name: "DemandeAttachmentsForm",

    methods: {
        onSubmit(event) {
            const form = event.currentTarget;
            const formData = new FormData(form);

            const fileInputsValidity = {};
            Array.from(formData.entries()).forEach(([name, value]) => {
                /** @var {File} value */
                if (!(value instanceof File)) {
                    return;
                }

                if (fileInputsValidity[name] === true) {
                    return;
                }

                if (!fileInputsValidity[name]) {
                    fileInputsValidity[name] = value.size > 0;
                }
            });

            Object.entries(fileInputsValidity).forEach(([name, isValid]) => {
                const input = document.getElementsByName(name)[0];
                if (isValid) {
                    input.setCustomValidity('');
                } else {
                    input.setCustomValidity('Veuillez ajouter au moins un document.');
                }

                if (!input.reportValidity()) {
                    event.preventDefault();
                }
            });
        }
    },
};
</script>

<template>
    <form method="post"
          accept-charset="UTF-8"
          enctype="multipart/form-data"
          @submit.stop="onSubmit"
    >
        <slot />
    </form>
</template>

<style scoped lang="scss">

</style>
