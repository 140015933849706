
<script>
    export default {
        props: {
        },
        data: function () {
            return {}
        },
        methods: {
            showModal: function (modalName) {
                this.$modal.show(modalName);
            }
        }
    }
</script>

<style scoped lang="scss">

    .versements {

    }
</style>
