<template>
    <div v-if="show" class="global-message position-relative d-flex justify-content-center">
        <div class="message-content d-lg-flex justify-content-center align-items-center">
            <div class="body2 message-text mr-5 mb-0 p-0">
                <slot />
            </div>
            <a v-if="cta" :href="cta.url" class="btn btn--ghost-black mt-4 mt-lg-0">{{ cta.label }}</a>
        </div>
        <button @click="close" class="close-btn position-absolute cross-icon"></button>
    </div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
    name: "GlobalMessage",

    props: {
        cta: {
            type: Object,
            default: null,
        },
        cookieName: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            show: true,
        }
    },

    methods: {
        close() {
            Cookies.set(this.cookieName, 0, { expires: 365 });
            this.show = false;
        }
    }
}
</script>

<style lang="scss">
@import "~@/assets/styles/components/_homepage.scss";
$light-grey: #fff;
$light-green: #BED76A;
$grey: #1C1C1C;

.global-message {
    background: $light-green;
    padding: 1rem;

    .message-content {
        max-width: 48rem;
        p {
            margin: 0;
        }

        .message-text {
            margin-right: 48px;
        }
        .btn {
            font-family: 'Plus Jakarta Sans', sans-serif;
            padding: 14px 20px;
            border-radius: 4px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            width: 132px;
            height: 44px;

            &--ghost-black {
                border: 1px solid $grey;
                color: $grey;
                &:hover {
                    background: $grey;
                    color: $light-grey;
                    text-decoration: none;
                }
            }
        }
    }

    .close-btn {
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
    }

    .cross-icon {
        height: 2rem;
        width: 2rem;
        background-image: url('~@/static/images/icons/cross.svg');
    }
}

@media screen and (max-width: 991px) {
    .global-message {
        padding: 16px;

        .message-content {
            width: 85%;
        }
    }
}

</style>
