<template>
    <button class="hamburger hamburger--collapse"
            :class="{ 'is-active': value }"
            type="button"
            :aria-label="value ? 'Fermer le menu' : 'Ouvrir le menu'"
            aria-controls="navigation"
            @click="$emit('input', !value)"
    >
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
    </button>
</template>

<script>
export default {
    name: "BurgerButton",

    props: {
        value: { type: Boolean, default: false },
    },
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/abstracts/_variables.scss";

$hamburger-padding-x: 0.9375em;
$hamburger-padding-y: 0;
$hamburger-layer-width: 1.125em;
$hamburger-layer-height: 0.09375em;
$hamburger-layer-spacing: 0.28125em;
$hamburger-layer-color: $gray-850;
$hamburger-layer-border-radius: 0.25em;

$hamburger-types: (collapse);
@import "~hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}
</style>
