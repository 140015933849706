<template>
    <modal name="resetPassword" :adaptive="true" height="auto" @closed="closedHandler">
        <div class="px-5 py-5 px-md-7 text-center">
            <h4>
                Veuillez mettre à jour votre mot de passe
            </h4>
            Dans le cadre de notre politique de sécurité, et compte tenu d’un contexte général croissant de piratage, nous vous recommandons vivement de mettre à jour votre mot de passe afin de renforcer la protection de votre compte personnel.
            <div class="d-flex justify-content-center mt-4">
                <a
                    :href="updatePasswordUrl"
                    class="btn btn-primary btn-sm mx-2"
                >
                    Mettre à jour mon mot de passe
                </a>

                <button
                    type="button"
                    class="btn btn-sm mx-2"
                    @click="$modal.hide('resetPassword')"
                >
                    Me le rappeler dans 3 mois
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import axios from "axios";

export default {
    name: "ResetPasswordModal",

    props: {
        updatePasswordUrl: {
            type: String,
            required: true,
        },
        snoozeUrl: {
            type: String,
            required: true,
        },
        userId: {
            type: String,
            required: true,
        },
    },

    mounted() {
        this.$modal.show('resetPassword');
    },

    methods: {
        closedHandler() {
            console.log('Update snooze date...');

            axios.post(
                this.snoozeUrl,
                {
                    date: new Date,
                }
            ).then((response) => {
                console.log(response);
            });
        },
    },
}
</script>
