<!-- Composant qui permet de gérer le formulaire d'activation. -->

<script>
    import SingleTapButton from './SingleTapButton';
    import PasswordField from "@/app/components/PasswordField";

    export default {
        components: { SingleTapButton, PasswordField },

        props: {
            isSuggestedEmailAvailable: {
                type: Boolean,
                default: false
            },
            keepSuggestedEmail: {
                type: String,
                default: ''
            },
        },

        data: function () {
            return {
                disableCpElsId: ['login-mail', 'login-mail-confirmation'],
                keepSuggestedEmailInput: null,
            }
        },

        mounted: function () {
            this.disableCopyPaste();
            this.keepSuggestedEmailInput = this.keepSuggestedEmail;
        },

        methods: {
            disableCopyPaste: function () {
                for (let id of this.disableCpElsId) {
                    ['paste', 'cut', 'drag', 'drop'].forEach(evt =>
                        document.getElementById(id) ? document.getElementById(id).addEventListener(evt, function (e) {
                            e.preventDefault();
                        }, false) : null
                    );
                }
            },
        },

        watch: {
            keepSuggestedEmailInput: function () {
                let that = this
                setTimeout(that.disableCopyPaste, 100)
            },
        },
    }
</script>
