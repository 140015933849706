<template>
    <vue-glide
        ref="carousel"
        :options="options"
        v-model="currentSlideId"
    >
        <slot></slot>
        <template #control
        >
            <button
                data-glide-dir="<"
                class="position-absolute arrow-button__left w-4 p-4"
            >
            </button>
            <button
                data-glide-dir=">"
                class="position-absolute arrow-button__right w-4 p-4"
            >
            </button>
        </template>
    </vue-glide>
</template>

<script>
import 'vue-glide-js/dist/vue-glide.css'
import { Glide, GlideSlide } from 'vue-glide-js'

export default {
    name: "HomepageFrequentSteps",

    props: {
        nbItems: {
            type: Number,
            required: true,
        },
    },

    components: {
        [Glide.name]: Glide,
    },

    data(){
        return {
            currentSlideId: 0,
            options: {
                perView: 1,
                gap: 30,
            },
        }
    },
}
</script>

<style scoped lang="scss">

$dark-blue: rgba(28, 28, 28, 0.4);;
$blue: #2A609D;
.arrow-button {
    font-size: 0;
    margin: 0 auto;

    &__right {
        width: 16px;
        background-image: url('~@/static/images/icons/arrow-right_white.svg');
        background-color: $dark-blue;
        border-radius: 100%;
        top: 35%;
        right: 3%;
        &:hover {
            background-image: url('~@/static/images/icons/arrow-right_grey.svg');
            background-color: white;
        }
    }

    &__left {
        width: 16px;
        background-image: url('~@/static/images/icons/arrow-right_white.svg');
        background-color: $dark-blue;
        border-radius: 100%;
        transform: rotate(180deg);
        top: 35%;
        left: 3%;
        &:hover {
            background-image: url('~@/static/images/icons/arrow-right_grey.svg');
            background-color: white;
        }
    }
}
</style>
