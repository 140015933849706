<template>
    <div class="row d-flex justify-content-lg-end justify-content-center position-relative">
        <div class="carousel-image col-12 col-lg-5">
            <transition-group mode="out-in" name="image-fade">
                <img
                    v-for="(image, index) in images"
                    v-show="currentSlideId === index"
                    :key="`carousel-image-${index}`"
                    :src="image.url"
                    :alt="image.alt"
                    width="640"
                    height="640"
                    class="carousel-image"
                />
            </transition-group>
        </div>
        <div class="carousel-text col-12 col-lg-7 position-relative text-center flex-column justify-content-end mx-0 px-0 pt-6 pb-3">
            <vue-glide
                ref="carousel"
                :breakpoints="breakpoints"
                :options="options"
                v-model="currentSlideId"
            >
                <slot></slot>
                <template #control>
                    <button
                        data-glide-dir="<"
                        class="position-absolute justify-content-center d-block arrow-button__left w-4 p-4"
                    >
                    </button>
                    <button
                        data-glide-dir=">"
                        class="position-absolute justify-content-center d-block arrow-button__right w-4 p-4"
                    >
                    </button>
                </template>
            </vue-glide>

            <ul class="folio list-unstyled d-flex justify-content-center" >
                <li :class="{'folio--active': currentSlideId === i-1}" v-for="i in nbItems" :key="i">
                    <button @click="currentSlideId = i-1"></button>
                </li>
            </ul>
        </div>
    </div>

<!--    </div>-->
</template>

<script>
import 'vue-glide-js/dist/vue-glide.css'
import { Glide, GlideSlide } from 'vue-glide-js'

export default {
    name: "HomepageCarousel",

    props: {
        nbItems: {
            type: Number,
            required: true,
        },
        images: {
            type: Array,
            required: true,
        }
    },

    components: {
        [Glide.name]: Glide,
    },

    data(){
        return {
            currentSlideId: 0,
            breakpoints: {
                993: {
                    perView: 1,
                    gap: 16,
                }
            },

            options: {
                perView: 1,
                gap: 100,
                autoplay: 3000,
            },
        }
    },

    computed: {
        currentImage() {
            return this.images[this.currentSlideId];
        }
    }
}
</script>

<style lang="scss">
$white: #CCCCCC;
$blue: #2A609DFF;
$semantic-blue: #224E80;


.carousel-image {
    border-radius: 50%;
    position: absolute;
    height: 640px;
    width: 640px;
    top: -10px;
    left: -11vw;
    object-fit: cover;
}

.image-fade-enter-active, .image-fade-leave-active {
    transition: opacity .4s ease-out;
}
.image-fade-enter, .image-fade-leave-to {
    opacity: 0;
}

.carousel-text {

    ::v-deep .glide__slides li {
        margin-bottom: 10px;
        padding: 100px 20px;
    }
    .folio {
        button {
            background: $white;
            width: 40px;
            height: 2px;
            margin-right: 4px;
        }
        &--active {
            button {
                background: $blue;
                height: 4px;
            }
        }
    }

    .arrow-button {
        font-size: 0;

        &__right {
            width: 16px;
            background-image: url('~@/static/images/icons/arrow-right_white.svg');
            background-color: $blue;
            background-position-y: 1px;
            border-radius: 50%;
            top:50%;
            left: 107%;
            transition: all 0.3s ease-out;

            &:hover {
                background-color: $semantic-blue;
            }
        }

        &__left {
            width: 16px;
            background-image: url('~@/static/images/icons/arrow-right_white.svg');
            background-color: $blue;
            background-position-y: 2px;
            border-radius: 50%;
            transform: rotate(180deg);
            top: 50%;
            left: -15%;
            transition: all 0.5s ease-out;

            &:hover {
                background-color: $semantic-blue;
            }
        }
    }
}

@media screen and (max-width: 1320px) {
    .carousel-text {
        .arrow-button {
            &__right {
                left: calc(107% - 40px);
            }
            &__left {
                left: calc(-15% + 45px);
            }
        }
    }
}

@media screen and (max-width: 1234px) {
    .carousel-text {
        .arrow-button {
            &__right {
                left: calc(107% - 55px);
            }
            &__left {
                left: calc(-15% + 60px);
            }
        }
    }
}


@media screen and (max-width: 993px) {

    .carousel-image {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 257px;
        height: 257px;
    }

    .carousel-text {
        padding: 24px 0;
        margin-top: 220px;
        width: 100%;

        .arrow-button {
            display: none !important;
            &__left {
                display: none !important;
            }
            &__right {
                display: none !important;
            }
        }
    }
}

</style>
