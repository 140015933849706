<template>
    <a :href="url" class="my-account-button btn btn-primary p-3">
        <img src="@/static/images/icons/cavp.svg" width="18" height="18" alt="" class="my-account-button__icon"/>
        Mon compte personnel
    </a>
</template>

<script>
export default {
    name: 'my-account-button',

    props: {
        items: { type: Array, default: () => [] },
        url: { type: String, required: true },
    }
}
</script>

<style lang="scss">
.my-account-button {
    display: flex;
    justify-content: center;
    align-items: center;

    white-space: nowrap;

    padding: 10px 0;

    &__icon {
        margin-right: 0.625rem;
    }
}

</style>
