<!-- Composant qui permet de gérer l'affichage des champs de la situation familiale du cotisant. -->

<script>
    export default {
        props:    {
            familySituation:   { type: String, default: '' },
            conjointSituation: { type: String, default: '' },
            hidden:            { type: String, default: '' },
        },
        data:     function () {
            return {
                isHidden:                      !!this.hidden,
                selectFamilySituationSelected: this.familySituation,
                cbJointSituation:              !!this.conjointSituation,
            };
        },
        methods:  {},
        computed: {},
    };
</script>
