<template>
    <div class="search-panel">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-8">
                    <search-form :search-url="searchUrl" class="search-panel__form" />
                </div>
            </div>
        </div>

        <button @click="$emit('close')" class="search-panel__close-button" type="button">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="24">
                <path stroke="#666" stroke-linejoin="round" stroke-width="1.2" d="M20 4 4 20M4 4l16 16"/>
            </svg>
        </button>
    </div>
</template>

<script>
import SearchForm from '@/app/components/SearchForm';

export default {
    name: "HeaderSearchPanel",
    components: { SearchForm },

    props: {
        searchUrl: {type: String, required: true},
    },
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/abstracts/_variables.scss";

.search-panel {
    position: relative;

    border-top: 1px solid $gray-200;
    background: $white;

    &__close-button {
        position: absolute;
        top: 1.25rem;
        right: 0;

        padding: 0.75rem;
    }

    &__form {
        padding: 3rem 0;
    }
}
</style>
