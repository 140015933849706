<template>
    <div class="mobile-menu-link-list">
        <ol>
            <li v-if="hasBackButton"
                @click="$emit('back')"
                class="mobile-menu-link-list__item mobile-menu-link-list__item--back body-1"
            >
                Retour
            </li>
            <li v-for="(item, key) in items"
                :key="key"
                @click="$emit('item-click', item)"
                class="mobile-menu-link-list__item body-1"
                :class="{ 'mobile-menu-link-list__item--has-children': item.submenu instanceof Array && item.submenu.length }"
            >
                <span v-if="item.submenu instanceof Array && item.submenu.length" v-text="item.cta.label"/>
                <a v-else
                   :href="item.cta.url"
                   :target="item.cta.target"
                   v-text="item.cta.label"
                />
            </li>
        </ol>
    </div>
</template>

<script>
export default {
    name: "MobileMenuLinkList",

    props: {
        items: {type: Array, required: true},
        hasBackButton: {type: Boolean, default: false},
    },
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/abstracts/_variables.scss";

.mobile-menu-link-list {
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 1.25rem 0;

        color: $gray-850;
        cursor: pointer;

        &:not(:first-child) {
            border-top: 1px solid $gray-400;
        }

        &--has-children::after,
        &--back::before {
            content: url("~@/static/images/icons/arrow-right.svg");
            width: 1.5rem;
            height: 1.5rem;
        }

        &--back {
            justify-content: flex-start;
            &::before {
                transform: rotate(180deg);
                margin-right: 1rem;
            }
        }

        & > a {
            color: inherit;
        }
    }
}
</style>
