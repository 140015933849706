<template>
    <form :action="searchUrl" class="search-form">
        <input type="search"
               name="q"
               placeholder="Rechercher…"
               class="search-form__input body-2"
        >

        <button type="submit" class="search-form__submit-button">
            <img src="@/static/images/icons/search.svg" alt="" width="24" height="24"/>
        </button>
    </form>
</template>

<script>
export default {
    name: "SearchForm",

    props: {
        searchUrl: {type: String, required: true},
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/abstracts/_variables.scss";

.search-form {
    position: relative;

    display: flex;
    align-items: center;

    &__input {
        width: 100%;

        padding: 0.875rem 2.5rem 1rem 1rem;

        border: 1px solid $gray-400;
        border-radius: 0.25rem;

        background: $gray-200;
    }

    &__submit-button {
        position: absolute;
        right: 0;

        padding: 0.75rem;
    }
}
</style>
