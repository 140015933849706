<template>
    <div class="PostalCodeCompletion form-row">
        <div class="form-group col-md-4">
            <label for="postal-code">Code postal</label>
            <input
                type="text"
                class="form-control"
                :class="{'is-invalid': cpErrors.length > 0 }"
                id="postal-code"
                maxlength="5"
                autocomplete="off"
                :name="postalName"
                :readonly="disabled || hasPendingDemande"
                :disabled="disabled || hasPendingDemande"
                v-model="ppostal"
                v-mask="['#####']"
                title="Code postal valide."
                pattern="^(?!99)(?!00000)\d{5}$"
                @keyup="onInputPostalCode(1)"
            >
            <small
                class="form-text text-muted"
                v-if="hasPendingDemande">
                En cours de traitement
            </small>
            <div
                class="invalid-feedback"
                v-html="cpErrors.join('<br>')"
            ></div>
        </div>
        <div class="form-group col-md-6">
            <label for="city">Ville/Pays</label>
            <div class="suggestion">
                <input
                    type="text"
                    class="form-control suggestion-input custom-select"
                    :class="{'is-invalid': cityErrors.length > 0 }"
                    id="city"
                    v-model="pcity"
                    :name="cityName"
                    :readonly="disabled || hasPendingDemande"
                    :disabled="disabled || hasPendingDemande"
                    @focus="isFocused = true"
                    @blur="blurInput"
                    @keyup.down="editIdx(1)"
                    @keyup.up="editIdx(-1)"
                    @keyup.enter="blurInput()"
                    maxlength="39"
                    autocomplete="off"
                    pattern="[A-zÀ-ú0-9\s']*((-|\s)*[A-zÀ-ú0-9\s'])*"
                >
                <small
                    class="form-text text-muted"
                    v-if="hasPendingDemande">
                    En cours de traitement
                </small>
                <div
                    class="invalid-feedback"
                    v-html="cityErrors.join('<br>')"
                ></div>
                <div
                    class="suggestion-list"
                    v-show="isFocused">
                    <div
                        class="suggestion-list-item"
                        v-for="(city, cidx) in cities"
                        :key="cidx"
                        @click.prevent="setCity(city, cidx)"
                        :class="{'suggestion-list-item-active': cidx == idx}">
                        {{ city.nom }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        props: {
            postal: {type: String, default: ''},
            postalName: {type: String, default: null},
            city: {type: String, default: ''},
            cityName: {type: String, default: null},
            hasPendingDemande: {type: Boolean, default: false},
            cpErrors: {type: Array, default: () => []},
            cityErrors: {type: Array, default: () => []},
            disabled: {type: Boolean, default: false},
        },

        data() {
            return {
                ppostal: this.postal,
                pcity: this.city,
                cities: [],
                baseCities: [],
                isFocused: false,
                idx: 0,
            };
        },

        mounted() {
            this.onInputPostalCode(0);
        },

        methods: {
            editIdx(v) {
                this.idx += v;
                if (this.idx < 0) {
                    this.idx = this.cities.length - 1;
                } else if (this.idx >= this.cities.length) {
                    this.idx = 0;
                }

                this.setCity(this.cities[this.idx]);
            },

            blurInput() {
                setTimeout(() => this.isFocused = false, 200);
            },

            getCitiesFromPostalCode(postal) {
                return axios.get('https://geo.api.gouv.fr/communes', {
                    params: {
                        codePostal: postal,
                        boost: 'population',
                    },
                }).then((res) => res.data);
            },

            onInputPostalCode(autoedit) {
                if (this.ppostal.length >= 5) {
                    this.getCitiesFromPostalCode(this.ppostal).then((data) => {
                        this.baseCities = data.sort((a, b) => {
                            if (a.population === b.population) {
                                return 0;
                            }

                            return a.population < b.population ? 1 : -1;
                        });
                        this.cities     = this.baseCities;

                        if (!autoedit) {
                            return;
                        }

                        this.setCity('');
                    });
                }
            },

            setCity(city, idx) {
                this.isFocused = false;
                this.pcity     = city.nom;
                if (idx) {
                    this.idx = idx
                }
            },
        },
    }
</script>

<style>
    #city::after {
    }
</style>
