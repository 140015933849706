<template>
    <transition name="slide-down" mode="out-in">
        <slot></slot>
    </transition>
</template>

<script>
export default {
    name: "SlideDown"
}
</script>

<style scoped lang="scss">
.slide-down-enter-active, .slide-down-leave-active {
    transition: all 400ms ease;
}
.slide-down-enter, .slide-down-leave-to {
    transform: translateY(-100%);
    opacity: 0;
}
</style>
