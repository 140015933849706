<template>
    <button v-bind="$attrs" @click="onClick" :disabled="isDisabled">
        <slot></slot>
    </button>
</template>

<script>
    export default {
        name: 'SingleTapButton',

        data() {
            return {
                isDisabled: false,
            };
        },

        methods: {
            onClick(event) {
                const form = event.target.form;
                if (form.checkValidity()) {
                    this.isDisabled = true;
                    form.submit();
                }
            }
        }
    };
</script>

<style scoped>
    :disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
</style>
