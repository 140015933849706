<template>
    <div
        class="attachments-upload d-flex flex-column align-items-start"
        data-js="attachments-upload"
    >
        <div class="d-flex flex-row flex-wrap">
            <file-upload v-for="id in inputs"
                         :key="id"
                         :id="id"
                         :name="`${inputName}[]`"
                         type="file"
                         accept=".jpeg, .jpg, .gif, .png, .pdf"
                         class="attachments-upload__input"
                         @input="addInput"
                         @remove="removeInput(id)"
            />
        </div>
        <label :for="lastInputId" class="inline-block btn btn-ghost">
            Ajouter un fichier
        </label>
    </div>
</template>

<script>
import FileUpload from "@/app/components/FileUpload.vue";

export default {
    name: "AttachementsUpload",

    components: {
        FileUpload,
    },

    props: {
        inputName: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            i: 0,
            inputs: [],
        }
    },

    computed: {
        lastInputId () {
            return `${this.inputName}_${this.i}`;
        },
    },

    mounted() {
        this.addInput();
    },

    methods: {
        addInput() {
            this.inputs.push(`${this.inputName}_${++this.i}`);
        },

        removeInput(id) {
            const i = this.inputs.findIndex(i => i === id);
            this.inputs.splice(i, 1);
        }
    },
}
</script>
