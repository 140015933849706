<template>
    <div
        class="super-submit-button form-inline justify-content-center"
        :class="{'flex-column': cbLabel.length > 50}">
        <div class="custom-control custom-checkbox mr-3">
            <input
                type="checkbox"
                class="custom-control-input"
                :id="id"
                v-model="toggle"/>
            <label
                class="custom-control-label"
                :for="id">{{ cbLabel }}</label>
        </div>
        <button
            type="submit"
            class="btn btn-primary"
            :disabled="!toggle">{{ btLabel }}
        </button>
    </div>
</template>

<script>
    export default {
        props: {
            cbLabel: {
                type: String,
                default: 'Je valide les modifications.'
            },
            btLabel: {
                type: String,
                default: 'Valider'
            }
        },
        data: function () {
            return {
                toggle: false,
                id: null,
            }
        },
        methods: {},
        mounted () {
            this.id = this._uid
        }
    }
</script>

<style scoped lang="scss">
    .super-submit-button {
        .custom-control-label {
            margin-bottom: 0.5rem;
        }
    }
</style>
