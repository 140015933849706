<script>
export default {
    inheritAttrs: false,

    data() {
        return {
            value: undefined,
            pdfIconUrl: 'data:image/svg+xml;base64,PHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMTI4IDBjLTE3LjYgMC0zMiAxNC40LTMyIDMydjQ0OGMwIDE3LjYgMTQuNCAzMiAzMiAzMmgzMjBjMTcuNiAwIDMyLTE0LjQgMzItMzJ2LTM1MmwtMTI4LTEyOHoiIGZpbGw9IiNlMmU1ZTciLz48cGF0aCBkPSJtMzg0IDEyOGg5NmwtMTI4LTEyOHY5NmMwIDE3LjYgMTQuNCAzMiAzMiAzMnoiIGZpbGw9IiNiMGI3YmQiLz48cGF0aCBkPSJtNDgwIDIyNC05Ni05Nmg5NnoiIGZpbGw9IiNjYWQxZDgiLz48cGF0aCBkPSJtNDE2IDQxNmMwIDguOC03LjIgMTYtMTYgMTZoLTM1MmMtOC44IDAtMTYtNy4yLTE2LTE2di0xNjBjMC04LjggNy4yLTE2IDE2LTE2aDM1MmM4LjggMCAxNiA3LjIgMTYgMTZ6IiBmaWxsPSIjZjE1NjQyIi8+PGcgZmlsbD0iI2ZmZiI+PHBhdGggZD0ibTEwMS43NDQgMzAzLjE1MmMwLTQuMjI0IDMuMzI4LTguODMyIDguNjg4LTguODMyaDI5LjU1MmMxNi42NCAwIDMxLjYxNiAxMS4xMzYgMzEuNjE2IDMyLjQ4IDAgMjAuMjI0LTE0Ljk3NiAzMS40ODgtMzEuNjE2IDMxLjQ4OGgtMjEuMzZ2MTYuODk2YzAgNS42MzItMy41ODQgOC44MTYtOC4xOTIgOC44MTYtNC4yMjQgMC04LjY4OC0zLjE4NC04LjY4OC04LjgxNnptMTYuODggNy4yOHYzMS44NzJoMjEuMzZjOC41NzYgMCAxNS4zNi03LjU2OCAxNS4zNi0xNS41MDQgMC04Ljk0NC02Ljc4NC0xNi4zNjgtMTUuMzYtMTYuMzY4eiIvPjxwYXRoIGQ9Im0xOTYuNjU2IDM4NGMtNC4yMjQgMC04LjgzMi0yLjMwNC04LjgzMi03Ljkydi03Mi42NzJjMC00LjU5MiA0LjYwOC03LjkzNiA4LjgzMi03LjkzNmgyOS4yOTZjNTguNDY0IDAgNTcuMTg0IDg4LjUyOCAxLjE1MiA4OC41Mjh6bTguMDY0LTcyLjkxMnY1Ny4zMTJoMjEuMjMyYzM0LjU0NCAwIDM2LjA4LTU3LjMxMiAwLTU3LjMxMnoiLz48cGF0aCBkPSJtMzAzLjg3MiAzMTIuMTEydjIwLjMzNmgzMi42MjRjNC42MDggMCA5LjIxNiA0LjYwOCA5LjIxNiA5LjA3MiAwIDQuMjI0LTQuNjA4IDcuNjgtOS4yMTYgNy42OGgtMzIuNjI0djI2Ljg2NGMwIDQuNDgtMy4xODQgNy45Mi03LjY2NCA3LjkyLTUuNjMyIDAtOS4wNzItMy40NC05LjA3Mi03Ljkydi03Mi42NzJjMC00LjU5MiAzLjQ1Ni03LjkzNiA5LjA3Mi03LjkzNmg0NC45MTJjNS42MzIgMCA4Ljk2IDMuMzQ0IDguOTYgNy45MzYgMCA0LjA5Ni0zLjMyOCA4LjcwNC04Ljk2IDguNzA0aC0zNy4yNDh6Ii8+PC9nPjxwYXRoIGQ9Im00MDAgNDMyaC0zMDR2MTZoMzA0YzguOCAwIDE2LTcuMiAxNi0xNnYtMTZjMCA4LjgtNy4yIDE2LTE2IDE2eiIgZmlsbD0iI2NhZDFkOCIvPjwvc3ZnPg==',
            fileBlob: undefined,
        };
    },

    computed: {
        isPdf() {
            return this.value && this.value.type === 'application/pdf';
        },
    },

    methods: {
        onFileChange(event) {
            const input = event.target;
            input.setCustomValidity('');

            this.value = input.files[0];
            this.$emit('input', this.value);
        }
    },

    watch: {
        async value(newValue) {
            URL.revokeObjectURL(this.fileBlob);
            this.fileBlob = undefined;

            if (newValue instanceof File) {
                this.fileBlob = URL.createObjectURL(newValue, { type: newValue.type });
            }
        },
    },

    beforeDestroy() {
        if (this.fileBlob) {
            // Object URL need to be revoked to avoid memory leaks
            URL.revokeObjectURL(this.fileBlob);
        }
    }
}
</script>

<template>
    <div class="fileupload d-flex flex-column">
        <input class="sr-only" type="file" v-bind="$attrs" @change="onFileChange" tabindex="-1" />
        <div v-if="value"
             class="fileupload__preview"
             :class="{ 'fileupload__preview--image': !isPdf }"
        >
            <img :src="!isPdf ? fileBlob : pdfIconUrl" />
            <button v-if="value"
                    @click="$emit('remove')"
                    type="button"
                    class="fileupload__close"
                    title="Supprimer ce fichier"
            >&times;</button>
        </div>
        <div v-if="value"
             class="fileupload__filename"
             v-text="value ? value.name : ''"
             :title="value ? value.name : false"
        />
    </div>
</template>

<style scoped lang="scss">
.fileupload {
    width: 130px;
    margin-right: 20px;
    margin-bottom: 20px;

    &__preview {
        position: relative;
        width: 100%;
        height: 150px;
        background: #d4d4d4;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: 100%;
            max-height: 100%;
            transform: translate(-50%, -50%);
        }

        &--image img {
            inset: 0;
            transform: none;

            width: 100%;
            height: 100%;

            object-fit: cover;
        }
    }

    &__filename {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-top: 5px;
    }

    &__close {
        position: absolute;
        top: 5px;
        right: 5px;

        display: flex;
        justify-content: center;

        width: 20px;
        height: 20px;

        border-radius: 10px;
        background: #FFF;
        cursor: pointer;

        font-size: 16px;
        line-height: 14px;
        font-weight: 600;
        text-align: center;
    }
}
</style>
