<template>
    <div class="map-admin">
        <div class="map-admin__map-wrapper mr-6">
            <!-- eslint-disable -->
            <svg class="map-admin__map" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 585">
                <a href="#" class="map-admin__zone" :class="{ active:  currentZone && currentZone.id === String(1)}" @mouseover="setCurrentZoneById(1)" @mouseout="setCurrentZoneById()" @click.prevent="goToAnchor(currentZone.link)">
                    <g>
                        <path id="FR-61" class="st0"
                              d="M180.2,150.3l5.2-3l0.9-2.9l5,1.1l0,0l6.8-2.6l0,0l2.6,3.1l1.7-2.2l7.4,2.6L209,145l3.3-0.3l8.1-7l1.2,1.7l5.1-2.7l5.7,1.1l0,0l-1.2,2.4l1.6,1.6l8,0.6l-0.2,2.9l6.1,4.1l0.7,1.9l-1.9,1.9l3.7,2.3l0,0l0.1,3.6l6.1,5.5l-1.3,2.9l1.7,2.3l-2.6,4.2l-6,3.1l2.5,6.8l-1.3,1.2l0,0l-2.5,0.4l-3.5-4.7l-0.6,1.9l-4.3-1l-1.9-3.8l-4.1-0.7l-0.4-6.4l-3.4-3.5l-6.5,1.4l-3.6,5l-4.7-0.2l0,0l0.2-4.4l-4-0.4l-1.1-3.4l1.2-1.1l-2.6-2.2l-1.7-0.1l-0.9,3.6l-2.1-0.9l-1.3,2.4l-1.8-1.6l-4.7,0.1l-5.9,4.1l-0.7-2.6l-4,3.1l-4-4.1l0,0l5-7.5l-1.4-2.3l1.6-1.8l-4.8-2.8L180.2,150.3z"/>
                        <path id="FR-02" class="st0"
                              d="M346.8,70.2l7.9-1.6l5.3,1.2l2.2-2.4L367,69l2.3-2.6l6.5,2.1l0.6,1.8l3.9-2.2l-0.1,3.3l6.7,0.9l0,0l3.7,1.3l0,0l0.9,5.8l-2,5.7l1.8,1.5l-5.1,5.1l-0.5,2.9l-3.8,0.7l2.3,3.2l-1.2,10.5l0,0l-0.5,2.9l-4.7-3l-2.8,1.7l-0.2,2.2l-3-0.7l-5.5,2.7l0.4,6.4l3.9,3.4l-5.2,0.5l-0.9,1.9l1.6,2.7l-2.2,2.5l2.7-0.4l1.1,1.8l-8,10.2l0,0l-1,0.9l-3.2-2.2l-0.6-3.5l-1.6,1.3l-1-2.6l-1.7,0.6l-4-4.6l-0.1-5.5l-3.8-1.1l0,0l3.1-2.8l-1.9-2.4l-0.5,2.4l-0.2-2.1l-3.2-1.5l0.6,2.9l-2.6-3.5l3.4-0.4l-0.1-3.5l-2.2-0.1l-0.7-2.5l3.1-0.1l2-6.7l3-0.4l-2.4-1.7l-0.6-3l1.8-3.9l-1.9-3l1.1-5.3l0,0l0,0l0,0l0,0l0,0l-2.7-8.1l6.2-9.8l-2-0.5L346.8,70.2z"/>
                        <path id="FR-14" class="st0"
                              d="M227.5,107.3l0.5,9.5l3.1-0.1l-2.6,2.4l3.1,2.4l-0.4,3.6l2,0.8l-0.6,4.1l-2.2,1.4l3.3,2.1l-1.4,4.4l0,0l-5.7-1.1l-5.1,2.7l-1.2-1.7l-8.1,7L209,145l0.9,1.2l-7.4-2.6l-1.7,2.2l-2.6-3.1l0,0l-6.8,2.6l0,0l-5-1.1l-0.9,2.9l-5.2,3l0,0l0.8-1.2l-2.5,1l-2.8-2.1l-5.4,0.7l-3.2-3.1l5.8-5l-0.1-1.3l-2.3,0.3l0.8-2.2l4.6-0.5l3.5-4.1l-0.8-5.5l-1.8,0.9l1.5-1.7l-3-2.1l3-2.5l-1.6-1.9l-2,2.6l-4.3-2.7l-2.4-2.3l0.8-4.6l0,0l1.7-2.9l5.1-0.4l5.4,2.5l17.8,1.5l7.2,3.2l9.1-2.7l5.3-4.9l6.6-2l0,0L227.5,107.3z"/>
                        <path id="FR-27" class="st0"
                              d="M227.5,107.3l8.4-3.3l3.6,3.4l2.3-0.8l0.8,2.7l5.8-1.6l3.2,3.5l1.6-0.8l0.1,2.9l-2.5-0.5l-0.6,1.5l2.4,1.7l1.6-1.6l0.7,3.3l2.1,1.3l2.3-0.5l-0.6-2.5l3.6-0.7l1-2l4.8-0.3l1.5-5.3l3.2-2.2l7.8,1.5l3.3,2.6l2.4-0.5l0,0l3.7,8.5l-0.6,1.8l-1.3-1.7l-2,0.5l-0.2,2.1l0,0l-4,9.8l0,0l-4.1-0.5l-0.9,2.2l-1.7-0.2l0.9,4.9l2-0.2l-0.7,2.4l0,0l0,0l0,0l-2.2,1l0.5,3.2l-4.1,2.4l0.4,3.4l-1.8,1.3l-8.6-1.9l-0.3,2.7l-2.1-0.8l-2,2.1l-5.7,0.6l-2.5,2.9l0,0l-3.7-2.3l1.9-1.9l-0.7-1.9l-6.1-4.1l0.2-2.9l-8-0.6l-1.6-1.6l1.2-2.4l0,0l1.4-4.4l-3.3-2.1l2.2-1.4l0.6-4.1l-2-0.8l0.4-3.6l-3.1-2.4l2.6-2.4l-3.1,0.1L227.5,107.3z"/>
                        <path id="FR-50" class="st0"
                              d="M135.4,88.5l13.2,5.2l4.4-1.3l1-2l8.6,0.1l1.5,5.7l-2.9,1.6l-0.4,2.8l5.8,8.4l-0.6,3.7l2.7,0.3l0,0l-0.8,4.6l2.4,2.3l4.3,2.7l2-2.6l1.6,1.9l-3,2.5l3,2.1l-1.5,1.7l1.8-0.9l0.8,5.5l-3.5,4.1l-4.6,0.5l-0.8,2.2l2.3-0.3l0.1,1.3l-5.8,5l3.2,3.1l5.4-0.7l2.8,2.1l2.5-1l-0.8,1.2l0,0l-0.4,1.4l4.8,2.8l-1.6,1.8l1.4,2.3l-5,7.5l0,0l-8.7-0.4l0,0l-7.6-2.2l-4.2,4.7l-2.8,0.5l-2.8-1.8l-3.5-9.1l0,0l9.1,0.1l-2.4-1.7l1.1-1l-2.5,1.3l-5.1-5.8l-0.2-4.7l-1.7-0.8l2.9-6l-0.6-6l2.2,0.4l-2.3-1l-0.7,2.3l-0.9-2.4l-0.6-11.4l1.6-0.3l-1.6-0.7l-0.5,1.6l-3.5-7.4l-4.1-3l-3.2-10.3l2-2.3l-0.3-3.8l-4.1-2.8L135.4,88.5z"/>
                        <path id="FR-59" class="st0"
                              d="M342.3,59.8l0.7,0.1l0,0l-0.2,2.5l-2.6,1.2l-0.3-2.5l2.3-1.1l0,0L342.3,59.8z M321.5,2.2l2.9,7.1l-1.7,1.8l0.4,4.6l1.5,2.4l3.6,0.1l2.8,5.5l4.9,2.3l1.9-3.9l8.2-2.5l1.7,4.2l2.9,1.5l-0.9,2.9l2.2,8.7l3.5,2.2l4.1-2.7l1.8,0.6l-0.8,2.4l4.5-0.6l2.2,2.6l0,5.9l2,4l1.6-3l6.4,1.6l5.1-2.1l4.7,6.5l1-2l1.9,1.1l-3.3,9l3,0l1.2,4.1l-2.9,1.4l-0.8,4.4l0,0l-6.8-0.9l0.1-3.3l-3.9,2.2l-0.6-1.8l-6.5-2.1L367,69l-4.8-1.6l-2.2,2.4l-5.3-1.2l-7.9,1.6l0,0l-3.4-2.7l0,0l0.1-4.3l1.6-1.1l-1.2-1.6l3.6-4.3l-1.6-2.2l-5.7-0.5l3.1-2.7l-4.2-6l3.5-1.5l0-2l-2.2,0.9l0.5-2.7l-2.5-1.8l-2.8,0.7l-0.6-2.4l-3.5,0.2L331,33l3.3-3l-2.5-2.5l-1.8,0.4l1,1.9l-1.9,2l-1.4-1.8l-9.4,0l-3.5-3.2l-1.2,0.6l-1.4-4.4l3-1.7l-5.1-0.7L307,18l-5.2-12.6l0,0l3.4-0.9l0.5,1.2l0.5-1.6l14.6-4L321.5,2.2z"/>
                        <path id="FR-60" class="st0"
                              d="M286.3,108.9l3.2-6.1l-3.1-0.1l1.3-2.1l-2.4-2.7l1.3-0.6l-1-3.5l2.2-3.2h-2.6l2.5-4.4l1.5,0.4l0,0l2.3,1.1l0.3,2.4l5.1-1.2l3.9,2.4l4.9-1.4l5.5,0.9l6.7,4.4l1.2-1.2l2.7,2.7l2.3-0.9l1,2.5l1.6-3.4l4,0.9l0.4-3.1l4-0.8l-0.4-2.7l2.9,3.1l1-2.6l2.1,1.7l1-2.2l1.3,1.5l1.5-1l0,0l-1.1,5.3l1.9,3l-1.8,3.9l0.6,3l2.4,1.7l-3,0.4l-2,6.7l-3.1,0.1l0.7,2.5l2.2,0.1l0.1,3.5l-3.4,0.4l2.6,3.5l-0.6-2.9l3.2,1.5l0.2,2.1l0.5-2.4l1.9,2.4l-3.1,2.8l0,0l-0.2,2l-3.3,0.9l-0.8-1.3l-4.9,1.3l-1.8-1.7l-2.3,2.2l-2.3-0.2l-2.6-2.8l-1.8,1.8l0,0l-1.5-2.8l-2.6,1.1l-7.3-5l-3.6,2.2l-0.4-1.8l-2,1l-3.7-3l-3.3,2.2l-6.9,0.7l-3.9-1.1l-0.1-2.7l-1.5-0.5l0,0l0.2-2.1l2-0.5l1.3,1.7l0.6-1.8L286.3,108.9z"/>
                        <path id="FR-62" class="st0"
                              d="M301.5,5.2l0.2,0.1l0,0L307,18l3.1,2.5l5.1,0.7l-3,1.7l1.4,4.4l1.2-0.6l3.5,3.2l9.4,0l1.4,1.8l1.9-2l-1-1.9l1.8-0.4l2.5,2.5l-3.3,3l0.5,3.4l3.5-0.2l0.6,2.4l2.8-0.7l2.5,1.8l-0.5,2.7l2.2-0.9l0,2l-3.5,1.5l4.2,6l-3.1,2.7l5.7,0.5l1.6,2.2l-3.6,4.3l1.2,1.6l-1.6,1.1l-0.1,4.3l0,0l-6,0l-3.4,2.1l-0.6-3.7l-3.9,2.6l1-4.9l-2.9-0.8l-0.2,3.1l-5.3-2.1l-0.8-1.9l-1,1.9l-0.8-1.8l-2.3,0.6l-1.7,2.8l-1.8-1.4l0.5-2.8l4.4-2.6l-1.8-2.3l-3.9,1.5l-1.1-2.1l-0.2,1.7l-2.4-1l-5,2.5l-2.7-0.7l-0.4-3.3l-5.4-2.3l0-2.8l-1.6,1.3l-4.3-3.2l-5.8,2l-1-1.4l0,0l-3.6-3.1l1.3-9l2,1.6l-2.4-3.7l0.2-19.7l8.5-5.6L301.5,5.2z M342.1,60.1L342.1,60.1l-2.3,1.1l0.3,2.5l2.6-1.2l0.2-2.5L342.1,60.1z"/>
                        <path id="FR-76" class="st0"
                              d="M272.3,66.8l3-0.4l0.1,2l9.4,7.9l4.5,10.3l0,0l-1.5-0.4l-2.5,4.4h2.6l-2.2,3.2l1,3.6l-1.3,0.6l2.4,2.7l-1.3,2.1l3.1,0.1l-3.2,6.1l0,0l-2.4,0.5l-3.3-2.6l-7.8-1.5l-3.2,2.2l-1.5,5.3l-4.8,0.3l-1,2l-3.6,0.7l0.6,2.5l-2.3,0.5l-2.1-1.3l-0.7-3.3l-1.6,1.6l-2.4-1.7l0.6-1.4l2.5,0.5l-0.1-3l-1.6,0.8l-3.2-3.5l-5.8,1.6l-0.8-2.7l-2.3,0.8l-3.6-3.4l-8.4,3.3l0,0l0,0l0,0l-0.5,0l0,0l-7.1-2.4l-2-2.5l5.3-12.6l16.3-9.6L261,75L272.3,66.8z"/>
                        <path id="FR-80" class="st0"
                              d="M282.7,47.7l0.6,0.5l0,0l1,1.4l5.8-2l4.3,3.2l1.6-1.3l0,2.8l5.4,2.3l0.4,3.3l2.7,0.7l5-2.5l2.4,1l0.2-1.7l1.1,2.1l3.9-1.5l1.8,2.3l-4.4,2.6l-0.5,2.8l1.8,1.4l1.7-2.8l2.3-0.6l0.8,1.8l1-1.9l0.8,1.9l5.3,2.1l0.2-3.1l2.9,0.8l-1,4.9l3.9-2.6l0.6,3.7l3.4-2.1l6,0l0,0l3.4,2.7l0,0l-0.8,1.3l2,0.5l-6.2,9.8l2.7,8.1l0,0l-1.5,1l-1.3-1.5l-1,2.2l-2.1-1.7l-1,2.6l-2.9-3.1l0.4,2.7l-4,0.8l-0.4,3.1l-4-0.9l-1.6,3.4l-1-2.5l-2.3,0.9l-2.7-2.7l-1.2,1.2l-6.7-4.4l-5.5-0.9l-4.9,1.4l-3.9-2.4l-5.1,1.2l-0.4-2.4l-2.3-1.1l0,0l-4.5-10.3l-9.4-7.9l-0.1-2l-3,0.4l0,0l6-9.2l6.9,1.2l-6.2-5.6l0.7-5.7L282.7,47.7z"/>
                        <text transform="matrix(1 0 0 1 268 86)" class="map-admin__zone-label">Nord-Ouest</text>
                    </g>
                </a>
                <a href="#" class="map-admin__zone" :class="{ active:  currentZone && currentZone.id === String(2)}" @mouseover="setCurrentZoneById(2)" @mouseout="setCurrentZoneById()" @click.prevent="goToAnchor(currentZone.link)">
                    <g>
                        <path id="FR-09" class="st1"
                              d="M250.7,502.6l-1.3-5.1l8.2-5.4l-1.5-0.9l0.6-5l1.6,0.8l0.6-2.6l2.9-0.5l4.2,3.9l3.2-2l-3.7-2.3l6.8-3.1l-0.1-1.6l-3.3-1.2l1.6-2.9l5.6,6.1l1.7-0.7l-0.5-4.1l5.8,3.2l2.2-2.1l0,0l0.9,4.9l3,2l2.8-0.5l1.6,2.5l2.4-0.3l-0.3,4l1.8,1l0.9,4l-3,1.3l1.9,0.5l0.3,3.3l-4.6,1l-0.6,1.9l3.6,4.6l5.7-0.8l3.6,3.1l-0.3,1.9l0,0l-6.9,0.1l-1,2.5l-7.8,2.4l0,0l-2.5-0.9l0.4-1.5l-7.7-2.2l-2.9,0.2l-1.8,2.7l-3.4-6.5l-7.8,0.6l-3.7-4.5l-6.4,0L250.7,502.6z"/>
                        <path id="FR-11" class="st1"
                              d="M299.4,467.9l1.8,2.3l4.1-1.2l1.8,1.9l1.8-4.3l5.7,2.5l7.3-0.2l0,0l0.9,0.9l-2.2,3.3l3.3,3.5l2.9-2.2l0.8,2.8l2.7,0.9l1.4-3.5l2.6-0.6l-0.1-2.9l1,3.1l4.7,0.5l0.3,2.6l7.6,1.4l1.9,2.1l0,0l-7.2,10.9l-0.9,10.2l0,0l-7.5-4.5l-3.1,1.2l-2.1,3.4l-16.6-0.3l0.7,6.4l-6.6,4.3l-1.3-0.7l0,0l0.3-1.9l-3.6-3.1l-5.7,0.8l-3.6-4.6l0.6-1.9l4.6-1l-0.3-3.3l-1.9-0.5l3-1.3l-0.9-4l-1.8-1l0.3-4l-2.4,0.3l-1.6-2.5l-2.8,0.5l-3-2l-0.9-4.9l0,0l1.4-3.9l3.8,0l-0.5-2.9l2.1-2.8l2.1,2.8l0.6-1.5l2.9,0.7L299.4,467.9z"/>
                        <path id="FR-12" class="st1"
                              d="M306.8,399.9l0.3-1.9l3.3-1.1l2.4,1.6l5.5-0.6l3.2-4l1.9-7.3l5.5-5.6l1.5,5l3.6-1.1l5.1,13.4l0,0l4.3,4.9l-0.8,3.3l3.2,3.7l-1,10.2l1.3-0.7l0.4,1.8l2.9,0.8l-1.1,2.3l6.5-0.6l0.4,1.8l0,0l-4.6,4.5l7.8,4l-3.8,6.3l0,0l-3.9,1.1l-2.4,4.7l-6-1.4l-0.1,8.1l-5.2-0.1l0,0l-0.9-2.6l-3.7-1.2l-3.4,1.9l-3.5-1.6l-4-5l-0.4-4.6l-2.1-1.1l1.3-1.8l-8.3-8.4l-6.1-0.8l2.1-1.4L304,424l-6.3,3l0,0l-4.1-3.3l3.3-3.8l-4.2-1.1l0.5-2.7l0,0l1.3-1l-3-7.2l2-1.4l1.2,1.1l5.8-5.6l5.7-0.1L306.8,399.9z"/>
                        <path id="FR-24" class="st1"
                              d="M241.3,335.8l6,2.5l-1,2.9l2.5,2.5l2.4-2.8l1.2,1.3l5-0.6l2.8,4.7l3.4,0.3l-1.9,3l5.5,1.9l0,0l1.7,0.6l-1.4,2.1l2.8,1l-3.7,3.5l-0.4,2.9l2.1,1.8l-2.1,2.2l2.6,0.9l-1.5,1.5l2.3,1.8l4.3,0.5l-1.4,1.2l2.9,5l0,0l-1.6,0.8l1.2,7.6l-5.8,4.7l0.7,3.2l-7,4.1l-3,5.6l0,0l-4-4.1l-6.1,2.6l0.8-4l-2.3-1.9l-4.6,1.5l-2.4-1.8l-3.1,2.3l-8.1,0.6l-2.4-6.3l0,0l-1.4-3.7l2-1.2l-1.5-1.2L225,385l-2,3.1l-9.3-1.9l2.8-3.6l-1.3-1.9l2.9-7.2l-1.2-2.6l-3.5,0.8l0,0l1.8-5.3l0,0l2.1-2.1l3.3,1.2l5.4-5.4l-0.8-4.2l2.3-5.3l1.2,1l4.2-2.6l3-3.9l0.3-4.8l2.3,0L241.3,335.8z"/>
                        <path id="FR-30" class="st1"
                              d="M381.1,409l2.9,3.1l-0.7,5.1l3.2-1.1l5.3,4.1l2.9-4.2l2.7-0.5l0.1,3.2l1.8,0.4l0.6-3.2l2.2-0.3l6,4.2l0,0l3,4.8l0,6.1l5.1,4.3l-4.3,4.9l0,0l-4.1,3.2l1.1,1.2l-1.6,9.1l-5.9-0.7l-2.5,4.2l1.9,0.7l-0.4,1.3l-5.3,3.1l-0.8-1.1l0.4,1.5l-3.4,1.6l-0.4,2.2l0,0l-3.8-1.3l-1.6-4.1l0,0l-0.8-2l2.9,0.3l1.8-3.2l-2.1-5.4l-5.7-4.5l-1.8,0.7l0.6-2.3l-2.6-2.6l-4.7,0.5l1-3.3l-1.7-1.8l-4.3,0.8l-4.4,6.3l-2.1-0.6l-0.6-2.4l-3.4,1.9l-0.3-2.8l-2.7-0.1l0,0l3.8-6.3l-7.8-4l4.6-4.5l0,0l7.1,3.2l3.7-0.4l1.6-3.8l5.2,3.4l3.2,0.1l2-3.3l2.1,1.1l-1.5-1.9l1.6-3.6L378,418l1.2-1.5l-2.8-3.2l2.6-0.7L381.1,409z"/>
                        <path id="FR-31" class="st1"
                              d="M254.7,447.8l8.2-2l2.6,3.1l6-2.8l-2.6-1.6l2.6-0.3l0.3-2.2l3.5,1.1l1.1-2.2l0.6,1.2l2.6-1.9l0,0l1.4,5.9l2.3,1l0.2,2.9l2.3,1.9l-1.8,1.3l2.4,0.3l-1.3,3.6l6.2,2.8l3.2,4.4l3.1,1.3l2.1-2l-0.4,4.4l0,0l-1.7,1.5l-2.9-0.7l-0.6,1.5l-2.1-2.8l-2.1,2.8l0.5,2.9l-3.8,0l-1.4,3.9l0,0l-2.2,2.1l-5.8-3.2l0.5,4.1l-1.7,0.7l-5.6-6.1l-1.6,2.9l3.3,1.2l0.1,1.6l-6.8,3.1l3.7,2.3l-3.2,2l-4.2-3.9l-2.9,0.5l-0.6,2.6l-1.6-0.8l-0.6,5l1.5,0.9l-8.2,5.4l1.3,5.1l0,0l-7.5-1.8l-1.4,4l1.3,5.2l-8.3-0.5l0,0l-1.1-1.9l1.2-8.1l3.5,1l3.5-5.6l-1.6-4.1l-3.3,1.7l1.6-4l-5.2-3.2l3.2-4.7l2.3-0.4l-0.9-0.9l3.5-3.4l-1.1-0.8l0,0l6.6-6.1l6.3,0.6l2.9,2.4l2.4-5.3l-1.2-0.8l2.3-1.8l-0.9-2l6.2-1.7L254.7,447.8z"/>
                        <path id="FR-32" class="st1"
                              d="M218.3,436.6l2.3-1.1l1.1,1.7l2.4-2.9l3.6,1.8l12.1-5.2l2.9,3.2l3.2-2.4l0,0l1,2.1l3.7-1l-4,6.5l6,2.2l-0.4,6.1l2.4,0.2l0,0l10.2,12.5l-6.2,1.7l0.9,2l-2.3,1.8l1.2,0.8l-2.4,5.3l-2.9-2.4l-6.3-0.6l-6.6,6.1l0,0l-11.5-1.9l-1.4-2.6l-4.8,1l-2.7-8.3l-2.7-0.2l-2.8-5l-3.4,1.3l0,0l-6.1-0.2l0,0l-1.6-3.3l3.6-5.4l-1.3-4.1l1.4-0.1l0.3-3.1l-2.2-1.9l2.5-2.4l3.5,0.6l2.5-3.2l1.8,1.7l-1.2,1.4l3.3,1.9L218.3,436.6z"/>
                        <path id="FR-33" class="st1"
                              d="M171,344.6l-0.2,2.9l10.9,9.8l4.8,15.2l3.9,4.6l-3.1-7.1l-1.9-11.6l0,0l5,0.4l0.3-1.5l0.9,3l5.9,1.1l1.6,7.8l1.4-1.5l6.6,4.4l4-1.6l2.3,1.1l0,0l3.5-0.8l1.2,2.6l-2.9,7.2l1.3,1.9l-2.8,3.6l9.3,1.9l2-3.1l1.6,0.3l1.5,1.2l-2,1.2l1.4,3.7l0,0l-2.9-0.1l-0.4,2.4L223,392l-3.6,3l3.5,2.3l-4.8,6.5l-2.8-0.1l-0.7,7.7l1.6,2.5l-4.3,1.2l1.9,4.8l-4.5,2.6l0,0l-2.1-2.7l-1.5,0.5l0,3.5l-6.9-0.2l0.2-4.6l-10.7-6.6l-0.1-2.9l-16,1.6l1.7-4.9l-4-1.4l-7.1,3.8l0,0l2.6-11.2l7.8,1.4l-2.7-3.5l1.5,0l-5.5-4.6l-3.3,9.4l-0.6-1l4.3-49.4L171,344.6z"/>
                        <path id="FR-34" class="st1"
                              d="M354.6,440.5l2.7,0.1l0.3,2.8l3.4-1.9l0.6,2.4l2.1,0.6l4.4-6.3l4.3-0.8l1.7,1.8l-1,3.3l4.7-0.5l2.6,2.6l-0.6,2.3l1.8-0.7l5.7,4.5l2.1,5.4l-1.8,3.2l-2.9-0.3l0.8,2l0,0l-8.1,2.2l-10.8,7.5l-5.3,6.1l-7-0.2l-4.5,3.8l0,0l-1.9-2.1l-7.6-1.4l-0.3-2.6l-4.7-0.5l-1-3.1l0.1,2.9l-2.6,0.6l-1.4,3.5l-2.7-0.9l-0.8-2.8l-2.9,2.2l-3.3-3.5l2.2-3.3l-0.9-1l0,0l4-2.8l0.3-2.6l-2.4-2.9l1.2-5.4l4.5,2.6l6.4-2.4l1.1-2.2l0,0l5.2,0.1l0.1-8.1l6,1.3l2.4-4.7L354.6,440.5z"/>
                        <path id="FR-40" class="st1"
                              d="M163.1,408.6l7.1-3.8l4,1.4l-1.7,4.9l16-1.7l0.1,2.9l10.7,6.6l-0.2,4.6l6.9,0.2l0-3.5l1.5-0.5l2.1,2.7l0,0l0.5,4.3l11,1.6l-3.2,5.7l0.6,2.5l0,0l-0.7,5l-3.3-1.9l1.2-1.4l-1.8-1.7l-2.5,3.2l-3.5-0.6l-2.5,2.4l2.2,1.9l-0.3,3.1l-1.4,0.1l1.3,4.1l-3.6,5.4l1.6,3.3l0,0l-8.6,2l0-2.7l-6.1,3.4l-2.6-1l0.7-1.1l-3.2,2l-2.1-2.2l-15.6,5.2l-1.1-1l1.7-0.8l-2.2-1.6l-4.5,2.8l-9.6-1.8l0,0l3.2-6.8L163.1,408.6z"/>
                        <path id="FR-46" class="st1"
                              d="M275.2,376.4l3.7-1.6l4.5,1.1l6.5,6.2l4.4-3.2l1.6,1.5l4.7-1.4l0,0l1.2,6l3.3,4.9l-1.7,5.4l3.2,4.6l0,0l-0.4,1.9l-5.7,0.1l-5.8,5.6l-1.2-1.1l-2,1.4l3,7.2l-1.3,1l0,0l-7.6,1.5l-1.7,1.8l-1-1l0.3,1.7l-2.9-1.8l0.6,3.2l-1.9,1.1l-2.9-4l-4.6,5.4l-3.2-1.9l0.8-3.4l-3.7,1.6l-5.5-4.9l2.3-2.3l-3,0.8l0,0l-3.4-9.6l3.9-1.7l0,0l3-5.6l7-4.1l-0.7-3.2l5.8-4.7l-1.2-7.6L275.2,376.4z"/>
                        <path id="FR-47" class="st1"
                              d="M209.4,422.5l4.5-2.6l-1.9-4.8l4.3-1.2l-1.6-2.5l0.7-7.7l2.8,0.1l4.8-6.5l-3.5-2.3l3.6-3l1.3,1.6l0.4-2.4l2.9,0.1l0,0l2.4,6.3l8.1-0.6l3.1-2.3l2.4,1.8l4.6-1.5l2.3,1.9l-0.8,4l6.1-2.6l4,4.1l0,0l-3.9,1.7l3.4,9.6l0,0l-5.1,1.9l-1.9-2.1l-1.1,4.1l3.4,2.1l-1.8,4.8l-2.2,0.4l1.7,2.7l-4.3-0.3l-2,4.5l0,0l-3.2,2.4l-2.9-3.2l-12.1,5.2l-3.6-1.8l-2.4,2.9l-1.1-1.7l-2.3,1.1l0,0l-0.6-2.5l3.2-5.7l-11-1.6L209.4,422.5z"/>
                        <path id="FR-48" class="st1"
                              d="M354.7,379.3l1.8,0.8l3.1,8.6l4.6-1l0.2-2.9l1.5-0.3l1.5,3.1l3.2-0.5l4.9,5.5l0,0l1.7,8.7l2.5,2.3l1.4,5.5l0,0l-2,3.4l-2.6,0.7l2.8,3.2L378,418l2.1,2.7l-1.6,3.6l1.5,1.9L378,425l-2,3.2l-3.2-0.1l-5.2-3.4l-1.6,3.8l-3.7,0.4l-7.1-3.2l0,0l-0.4-1.8l-6.5,0.6l1.1-2.3l-2.9-0.8l-0.4-1.8l-1.3,0.7l1-10.2l-3.2-3.7l0.8-3.3l-4.3-4.9l0,0l5-14.1l1.6-1l2.1,2.3l2.1-4.3l1.8,0.5L354.7,379.3z"/>
                        <path id="FR-64" class="st1"
                              d="M151.9,462.5l9.6,1.8l4.5-2.8l2.1,1.6l-1.6,0.8l1.1,1l15.6-5.2l2.1,2.2l3.2-2l-0.7,1.1l2.6,1l6.1-3.4l0,2.7l8.6-2l0,0l6.1,0.2l0,0l3.2,6.3l-2,0.5l-0.1,3l2.8-2l1.3,5.6l-3.1,2.5l1.2,1.8l-2.3,2.7l0.2,2.6l-2.1-0.2l-3.1,3.7l0.5,3.5l-4.5,2.6l-1.1,8.8l0,0l-3.4,2.9l-4.6-1.6l-2,2.7l-6.8-6l-1.5-4.6l-7.9,0.8l-7.1-4l-1.2,0.9l-6.9-3.4l1.6-2.8l-2.6,0.7l-0.9,4.4l-3.6-1l-1.4-2.4l3.8-5.7l-0.3-3.9l-6.1-2l-3,2.4l-0.7-3.1l-4.4,0.6l-2.4-3.1l6.5-3.6L151.9,462.5z M211.1,471.4L211.1,471.4l0.1,3.7l0.9-2.9L211.1,471.4z M210.1,479l1.7-1.2l-1.8-2.2L210.1,479z"/>
                        <path id="FR-65" class="st1"
                              d="M210.5,475l1.3,2.7l-1.7,1.2L210.5,475z M212,472.1l0.1,0.1l0,0l-0.9,2.9l-0.6-3.3l0,0l0,0l0,0l0.5-0.4l0,0L212,472.1z M211.1,459.5l3.4-1.3l2.8,5l2.7,0.2l2.7,8.3l4.8-1l1.4,2.5l11.5,1.9l0,0l1.1,0.8l-3.5,3.4l0.9,0.9l-2.3,0.4l-3.2,4.7l5.2,3.2l-1.6,4l3.3-1.7l1.6,4.1l-3.5,5.6l-3.5-1l-1.2,8.1l1.1,1.9l0,0l-4.8-1.4l-2.7,2.9l-4.9-3.6l-9.7,2.5l-4.3-6l-6.3-2.9l0,0l1.1-8.8l4.5-2.6l-0.5-3.5l3.1-3.7l2.1,0.2l-0.2-2.6l2.3-2.7l-1.2-1.8l3.1-2.5l-1.3-5.6l-2.8,2l0.1-3l2-0.5L211.1,459.5z"/>
                        <path id="FR-66" class="st1"
                              d="M305,511.6l1.3,0.7l6.6-4.3l-0.7-6.4l16.6,0.3l2.1-3.4l3.1-1.2l7.5,4.5l0,0l0.3,16.6l3.7,1.5l1.4,4.5l-3.7,0.5l-1.9-2.7l-3.9-0.5l-7,3.9l-4.3,0.4l-0.1,3.6l-4-1l-1.9,1.4l-4-3.4l-7.3-2.5l-10.1,4.8l-3.2-5.7l-8.5-2.4l0.4-3.4l2-1l0,0l7.8-2.4l1-2.5L305,511.6z"/>
                        <path id="FR-81" class="st1"
                              d="M297.7,427l6.3-3l3.9,2.3l-2.1,1.4l6.1,0.8l8.3,8.4l-1.3,1.8l2.1,1.1l0.4,4.6l4,5l3.5,1.6l3.4-1.9l3.7,1.2l0.9,2.6l0,0l-1.1,2.2l-6.4,2.4l-4.5-2.6l-1.2,5.4l2.4,2.9l-0.3,2.6l-4,2.8l0,0l-7.3,0.2l-5.7-2.5l-1.8,4.3l-1.8-1.9l-4.1,1.2l-1.8-2.3l0,0l0.4-4.4l-2.1,2l-3.1-1.3l-3.2-4.4l-6.2-2.8l1.3-3.6l-2.4-0.3l1.8-1.3l-2.3-1.9l-0.2-2.9l-2.3-1l-1.4-5.9l0,0l0.9-1.1l-1.8-1.3l3.6,0l3.4-4.9l-1.8-4.1l4.9,1.2l1.1-1.7l1.3,1.1l0.2-2.2l2.9,0.8l-0.1-1.8L297.7,427z"/>
                        <path id="FR-82" class="st1"
                              d="M259.4,413.8l3-0.8l-2.3,2.3l5.5,4.9l3.7-1.6l-0.8,3.4l3.2,1.9l4.6-5.4l2.9,4l1.9-1.1l-0.6-3.2l2.9,1.8l-0.3-1.7l1,1l1.7-1.8l7.6-1.5l0,0l-0.5,2.7l4.2,1.1l-3.3,3.8l4.1,3.3l0,0l-3.4-0.8l0.1,1.8l-2.9-0.8l-0.2,2.2l-1.3-1.1l-1.1,1.7l-4.9-1.2l1.8,4.1l-3.4,4.9l-3.6,0l1.8,1.3l-0.9,1.1l0,0l-2.6,1.9l-0.6-1.2l-1.1,2.2l-3.5-1.1l-0.3,2.2l-2.6,0.3l2.6,1.6l-6,2.8l-2.6-3.1l-8.2,2l0,0l-2.4-0.2l0.4-6.1l-6-2.2l4-6.5l-3.7,1l-1-2.1l0,0l2-4.5l4.3,0.3l-1.7-2.7l2.2-0.4l1.8-4.8l-3.4-2.1l1.1-4.1l1.9,2.1L259.4,413.8z"/>
                        <text transform="matrix(1 0 0 1 222 436)" class="map-admin__zone-label">Sud-Ouest</text>
                    </g>
                </a>
                <a href="#" class="map-admin__zone" :class="{ active:  currentZone && currentZone.id === String(3)}" @mouseover="setCurrentZoneById(3)" @mouseout="setCurrentZoneById()" @click.prevent="goToAnchor(currentZone.link)">
                    <g>
                        <path id="FR-01" class="st2"
                              d="M413.6,308l6.7-20.5l4.8,2.1l4.6-2.1l2.8,3.7l3.3,0.7l0,0l-0.6,2l3.2,1.9l1.1,4.3l0.8-2.2l2.1,1.9l-0.1,3.1l2.7-0.2l4.3-4.6l2.9,2.1l0.5,2.9l6-0.2l8.1-9.1l0,0l4.4,2.9l-2.8,4.9l0.9,2l-6,2.1l-1,5l0,0l-2.6,0.8l-0.3,2l-2.8-1.1l0.3,9.8l0,0l-2.2,12.7l-3,0.8l-0.6,4.1l-2.6,1.7l0,0l-11.3-16l-5.3,6.8l-5-2.7l0,0l-7.6,0.6l-1.5-5.5l-3.2,0l0.2-1.4l-3.3-1.3l0.7-8.8l2.3-3.7L413.6,308z"/>
                        <path id="FR-04" class="st2"
                              d="M450.8,424.6l5-1.3l4.7,1.3l-3.5-5.3l1.7-0.8l2.3,2.8l-0.8-5.1l2-3.4l5.1-2.4l0.3-2.3l3.1,0.9l2.9,4.6l1.4-1.8l-1.2-2.9l3.9-0.1l1.5-3.4l4.4,4.1l6.8,0.2l2-5.4l11.2-6.6l0,0l0.7,2.1l-4.7,5.2l3.9,5.8l-2.3,0.5l-0.2,3.5l0,0l-3.2,1.7l-5.2,9.4l2.5,7.4l8.1,8.2L501,442l-2.2-1.7l-3.4,2.7l-3.2-1l1.2,1.8l-1.5,1.5l1.9,1l-3.2,1.4l0,0l-9.1-0.2l-1.3,3.3l-7-3.8l-8,7.4l-4-5.1l-3.2,2.3l-0.8-1.7l-2.9,0.9l0,0l-4.4-5.3l-4.5,0.5l2.6-5.7l-4.2-2.1l1.5-6.7l-1.6,0.2l-0.2-3l0,0l3.3-2.1l0-2.1l4.2,2.4l-1.5-1.3L450.8,424.6z"/>
                        <path id="FR-05" class="st2"
                              d="M475,370.2l3,0.2l1.3,3.1l3.6,1.1l2.5-3.4l5,0l0,0l1.8,5.3l2.9,0.3l0.3,6.4l4.7,3.2l3-0.7l3.6,2.3l-0.9,2l3.2,6.1l-3.7-0.4l-1.5,2l0,0l-11.2,6.6l-2,5.4l-6.8-0.2l-4.4-4.1l-1.5,3.4l-3.9,0.1l1.2,2.9l-1.4,1.8l-2.9-4.6l-3.1-0.9l-0.3,2.3l-5.1,2.4l-2,3.4l0.8,5.1l-2.3-2.8l-1.7,0.8l3.5,5.3l-4.7-1.3l-5,1.3l0,0l0.4-4.4l-3.3-2.3l0.9-1.6l-5.7-0.3l-3-5l3.3-0.6l-1.6-3.7l6,2l2.5-2.1l-2.8-2.4l1.9-6.2l6.1-0.2l1.7-2.2l-1.3-0.9l0,0l1.2-3.1l5.2-0.1l1.2-1.2l-1.3-1.4l3-1.9l1.4,1.2l3.2-2.8l9.2,0.6l-1.3-8.4l-5-1L475,370.2z"/>
                        <path id="FR-06" class="st2"
                              d="M501.8,415l5.2,7.7l6.5,1.3l7.1,5l13.6-3.4l-0.7,2.5l2,2.8l-2.6,6.3l-6.4,5.8l1.5,5l-1.8,2.2l-6.1,2.1l-0.2,2.1l-0.7-1.7l-6.6,3l-0.8,6.1l-1.6-1.4l-2.5,2l-2.8-0.6l-1.8,3.9l0,0l-2.1-3l1.4-4l-4.7-1.7l-1.9-6.5l-4-0.4l-1-2.3l0,0l3.2-1.4l-1.9-1l1.5-1.5l-1.2-1.8l3.2,1l3.4-2.7l2.2,1.7l2.4-0.6l-8.1-8.2l-2.5-7.4l5.2-9.4l3.2-1.7l0,0L501.8,415z"/>
                        <path id="FR-07" class="st2"
                              d="M412.6,356.1l1.9,4.1l0,0l1.2,8.2l-1.2,1.9l3.5,10.9l-5.2,9.6l0.8,6.6l-3.6,5.8l-1.8,13.3l0,0l0,3.4l0,0l-6-4.2L400,416l-0.6,3.2l-1.8-0.4l-0.1-3.2l-2.7,0.5l-2.9,4.2l-5.3-4.1l-3.2,1.1l0.7-5.1l-2.9-3.1l0,0l-1.4-5.5l-2.5-2.3l-1.7-8.7l0,0l2.6-1.4l1.1-3.4l3.3-0.4l0.3-2.3l5.1,0l2.8-5.3l3.7-0.6l-0.7-2.7l3.6-1.3l-1.5-1.6l2.2-2.2l-1-2.3l3.5,1.5l1.1-6.9l0,0l4.9-0.9l0.6-3.6L412.6,356.1z"/>
                        <path id="FR-13" class="st2"
                              d="M411.9,440l8.9,2.6l4,5.2l5.3,2.9l5.5-0.1l9.1,4.5l3.1,0l6.3-3.7l0,0l2.5,2l-0.7,1.7l-4,0.9l-1.8,4l2.9,1.3l-1.1,3.9l3.7,3.5l-4.4,1.2l0.9,2.5l-1.4,2.1l3.8,2.1l-3.3,2.7l-0.5,3.1l0,0l-2-0.4l-0.7,1.5l-2.8-3.1l-7.5,0.4l0.8-2.6l-2-5.7l-11.5,1.8l-3.3-5.7l-3.4,0.7l-1.1-2.4l0.7,2.5l-2.2-0.8l2.2,3.9l-1.7,1.6l-10.6-1.9l0.4-3.5l-2.4-1.7l-12.6-0.5l0,0l0.4-2.2l3.4-1.6l-0.4-1.5l0.8,1.1l5.3-3.1l0.4-1.3l-1.9-0.7l2.5-4.2l5.9,0.7l1.6-9.1l-1.1-1.2L411.9,440z"/>
                        <path id="FR-2A" class="st2"
                              d="M571,527.5l8.2,3.1l3.8-0.3l1.8,4.3l5.8,2.6l4.8,10.2l2.5-0.2l0.4,9.9l3.5,1l3.5-1.5l0,0l-0.6,11.2l-1.4,2.2l-1.9-0.7l-0.9,2.9l1.3-1.6l2.1,0.6l-4,3.5l0.2,3.7l-2.6,3l2-0.8l-2.1,3.1l-5-1.4l0.9-2.6l-2-1.4l-9.3-2.9l0.1-1.6l-2.4-0.5l-0.1-3.5l5.4-3.8l-5.6-0.7l-0.3-2.3l-4.8,0l3-2l-0.8-1.4l2.5-0.5l1.3-4.7l-2.3-2.1l-5.8,2.2l-0.6-4l3-0.7l-0.2-1.9l3.6-2.1l-2-3.5l-1.7,0.7l-4.2-2.5l1.5-1.3l-2.2-3.9l6.3-1.7l-3.7-2.1l0.2-2.5l-2.3,0.9L571,527.5z"/>
                        <path id="FR-2B" class="st2"
                              d="M606.3,492l1.7,1.4l1.2,10.2l-2,7.4l3.6,7.1l1.1,19.8l-0.4,5.1l-5.7,8.4l-0.4,5.2l0,0l-3.5,1.5l-3.5-1l-0.4-9.9l-2.5,0.2l-4.8-10.2l-5.8-2.6l-1.8-4.3l-3.8,0.3l-8.2-3.1l0,0l3.5-2l0.4-5.5l2.2-0.5l-0.5-2.9l3.2,1l0.6-2.5l8.8-2.2l4.5-5.1l4-0.2l3.2,3.3l1.9-3.3l-1.5-5.5l2-5.2l-0.8-3.9L606.3,492z"/>
                        <path id="FR-26" class="st2"
                              d="M414.5,360.2l7.8-2.8l3.6,3.6l2.6-0.9l-0.7,2.9l3.4,1.8l-1.5,1l0.8,4.8l-2.1,2.7l3.1-0.6l7.2,2.9l4.1-2.9l-1,16.6l4,1.9l-0.2-1.6l4.1,4.1l6.3,1.1l0,0l1.3,0.9l-1.7,2.1l-6.1,0.2l-1.9,6.1l2.8,2.5l-2.5,2.1l-6-2l1.6,3.7l-3.2,0.6l3,5l5.7,0.3l-0.9,1.6l3.3,2.3l-0.4,4.4l0,0l-1.4,1.3l1.5,1.3l-4.2-2.4l0,2.1l-3.3,2.1l0,0l-4.8-2.3l-1.2-3.4l-8.3-0.9l0.5-4.9l-2.5,2.2l-2-1.7l-10,4.6l-1.3-5l-5.7-1l0,0l1.8-13.3l3.6-5.8l-0.8-6.6l5.2-9.6l-3.5-10.9l1.2-1.9L414.5,360.2z M422.2,411.1L422.2,411.1l-3.2,1.5l-0.9,5.4l4,0.7l3.5-4.9l-2.9-2.1L422.2,411.1z"/>
                        <path id="FR-38" class="st2"
                              d="M427,329.6l5,2.7l5.3-6.8l11.3,16l0,0l4.7,10.2l7,3l0.2-5.1l2.1-0.9l3.4,3.2l3.4,0.1l2.6,4.8l-2.7,3.9l0.6,7.7l4.4,0.2l0.4,1.5l0,0l-2.3,6.5l5,1l1.3,8.4l-9.2-0.6l-3.2,2.8l-1.4-1.2l-3,1.9l1.3,1.4l-1.2,1.2l-5.2,0.1l-1.2,3.1l0,0l-6.3-1.1l-4.1-4.1l0.2,1.6l-4-1.9l1-16.6l-4.1,2.9l-7.2-2.9l-3.1,0.6l2.1-2.7l-0.8-4.7l1.5-1l-3.4-1.8l0.7-2.9l-2.6,0.9l-3.6-3.6l-7.8,2.8l0,0l-1.9-4.1l0,0l0-5.3l0,0l4.8-4.4l-4-3.4l10.8-1.6l0.9-3l4-2.2l-3.7-3.9L427,329.6z"/>
                        <path id="FR-42" class="st2"
                              d="M377,302.1l-0.4,3.7l3.4,0.7l0.9,1.8l8.9-1.3l2.8,2.3l4-1.7l0.7-2.1l0,0l2,3.6l-4.7,1.8l-0.8,3.6l-2.2,1.2l2.6,2.6l-2.9,0.6l6.3,8l-2,5.3l2.2,1.5l-1.5,4.4l4.3,5l6,0.6l1.1,1.9l1.6-1l-1.1,4.3l2.9-0.4l1.4,2.3l0,0l0,5.3l0,0l-5.9,3.2l-0.6,3.6l-4.9,0.9l0,0l-1.9-2.3h-3.6l-0.1-5.2l-3.1,0.3l-1.6-1.8h-3.1l-5.8,3.6l-1.7-2.8l-2.8,2.5l-0.4-1.4l0,0l-0.2-2.5l3-2.1l0.9-3.5L368.3,331l2.7-5.6l-2.6-2.6l0,0l0.6-2.6l5.1-1.5l-2.6-14.3L377,302.1z"/>
                        <path id="FR-69" class="st2"
                              d="M397.3,305.4l1.6-4.9l3.2,2.1l2.4-1.6l2.4,1.8l2.6-2.4l1.3,1.6l-1.3,1.2l2.4,1.5l-0.4,3.2l2.2,0.2l0,0l0.9,1.5l-2.3,3.7l-0.7,8.8l3.3,1.3l-0.2,1.4l3.2,0l1.5,5.5l7.6-0.6l0,0l-1.5,2.7l3.7,3.9l-4,2.2l-0.9,3l-10.8,1.6l4,3.4l-4.8,4.4l0,0l-1.4-2.3l-2.9,0.4l1.1-4.3l-1.6,1l-1.1-1.9l-6-0.6l-4.3-5l1.5-4.4l-2.2-1.5l2-5.3l-6.3-8l2.9-0.6l-2.6-2.6l2.2-1.2l0.8-3.6l4.7-1.8L397.3,305.4z"/>
                        <path id="FR-73" class="st2"
                              d="M457.1,322.2l1.5,0.6l0.4,5.6l1.7,1.8l2.1-0.5l1.3,3.9l7.1-0.5l2.6,4.4l4.2-0.6l7.5-12.8l2.6,1.7l-1.5,1.1l1.1,2.5l5.4,2.9l-0.1,2.5l4.6-3.1l0,0l1.1,4.6l7.2,3.8l0,8l7.7,5.8l-3.2,4.6l1,4.2l-2.2,2.3l-3.3-0.2l-2.3,3l-2.2,0.1l0,1.9l-5.2-1.3l-5.7,2.9l0,0l-5,0l-2.5,3.4l-3.6-1.1l-1.3-3.1l-3-0.2l0,0l-0.4-1.5l-4.4-0.2l-0.6-7.7l2.7-3.9l-2.6-4.8l-3.4-0.1l-3.4-3.2l-2.1,0.9l-0.2,5.1l-7-3l-4.7-10.2l0,0l2.6-1.7l0.6-4.1l3-0.8L457.1,322.2z"/>
                        <path id="FR-74" class="st2"
                              d="M497.1,296.6l-0.3,2l3.3,3.2l-2.8,8.5l4.2,0.8l-1,4.4l2.6-0.7l4.5,8.3l-4.3,4.6l-5,0.6l-0.9,3.4l0,0l-4.6,3.1l0.1-2.5l-5.4-2.9l-1.1-2.5l1.5-1.1l-2.6-1.7l-7.5,12.8l-4.2,0.6l-2.6-4.4l-7.1,0.5l-1.3-3.9l-2.1,0.5l-1.7-1.8l-0.4-5.6l-1.5-0.6l0,0l-0.3-9.8l2.8,1.1l0.3-2l2.6-0.8l0,0l7.1-0.4l6.9-5.1l0.6-1.9l-2,0.3l-1.7-3.6l1.3-2.9l11.1-5.8l12.5,1.8L497.1,296.6z"/>
                        <path id="FR-83" class="st2"
                              d="M454.3,450.9l2.9-0.9l0.8,1.7l3.2-2.3l4,5.1l8-7.4l7,3.8l1.3-3.3l9.1,0.2l0,0l1,2.3l4,0.4l1.9,6.5l4.7,1.7l-1.4,4l2.1,3l0,0l-3.1,3.7l-4.6-0.2l-1.1,3.9l-5.5,3.9l4.6,0.8l-2.4,5.4l-3.2-1l-7.3,2.5l-0.7,3.1l-6.8-1.5l-1.8,1.6l-0.2,3.5l-2.5-0.1l1.2-2.9l-4.3,0.1l-4.1-2.6l-1.7,1.3l2.8,2l-5,1l-0.8-3.7l-4.7-1.6l-1-2l0,0l0.5-3.1l3.3-2.7l-3.8-2.1l1.4-2.1l-0.9-2.5l4.4-1.2l-3.7-3.5l1.1-3.9L450,460l1.8-4l4-0.9l0.7-1.7l-2.5-2l0,0L454.3,450.9z"/>
                        <path id="FR-84" class="st2"
                              d="M408.2,416.5l5.7,1l1.3,5l10-4.6l2,1.7l2.5-2.2l-0.5,4.9l8.3,0.9l1.2,3.4l4.8,2.3l0,0l0.2,3l1.6-0.2l-1.5,6.7l4.2,2.1l-2.6,5.7l4.5-0.5l4.4,5.3l0,0l-0.2,0.5l0,0l-6.3,3.7l-3.1,0l-9.1-4.5l-5.5,0.1l-5.3-2.9l-4-5.2l-8.9-2.6l0,0l4.3-4.9l-5.1-4.3l0-6.1l-3-4.8l0,0L408.2,416.5z M422.3,411.3l0.5,0.5l0,0l2.9,2.1l-3.5,4.9l-4-0.7l-0.6-2.6l2.9-3.7l0,0l0,0l0,0l1.9-0.5l0,0L422.3,411.3z"/>
                        <text transform="matrix(1 0 0 1 422 376)" class="map-admin__zone-label">Sud-Est</text>
                    </g>
                </a>
                <a href="#" class="map-admin__zone" :class="{ active:  currentZone && currentZone.id === String(4)}" @mouseover="setCurrentZoneById(4)" @mouseout="setCurrentZoneById()" @click.prevent="goToAnchor(currentZone.link)">
                    <g>
                        <path id="FR-16" class="st3"
                              d="M223.1,312.9l9.7,2.8l2.2-1l-1.6-2.1l2-2.1l4.3,3.9l3.8-1.4l1.1-2.6l4.6,0.7l0,0l-0.4,4.6l4.7,2.5l0.6,3.1l-3.4,2.4l-2-0.9l-1.2,8.4l-2.9-0.8l0.1,2.5l-3.5,3.1l0,0l-2.7,4.5l-2.3,0l-0.3,4.8l-3,3.9l-4.2,2.6l-1.2-1l-2.3,5.3l0.8,4.2l-5.4,5.4l-3.3-1.2l-2.1,2.1l0,0l-0.1-2.1l-4.9-1.2l0.2-2.5l-4.6-1.9l-2.7,0.8l1.6-3.1l-2.7-1.4l3.1-1.5l-2.1-2.7l1.9-2.7l-3.3-1.9l0.5-2.5l-5.1-2.8l2-1.8l-1.7-1.8l0.9-3l-2.5-0.5l7.2-3.8l5.5,1.6l1.8-5.4l-1.6-3.3l2.2-0.1l-0.2-2.5l0,0l2.7-1.4l0.1-3.7l2.3,0.1l3.3-3.2L223.1,312.9z"/>
                        <path id="FR-17" class="st3"
                              d="M156.7,315.7l7,3.8l2.1,5.8l-1.8,5.2l-1.6-4.8l-4.9-4.3L156.7,315.7z M152.4,303.2l1.5,1.4l-1.6,0l0.5,1.8l3.7-1.6l-1.1,1.3l7.8,2.8l-2.4,1.2l-9.3-3.5l-1.2-2.5L152.4,303.2z M168.8,299.6l7.7-3.3l-1.3,2.9l1.2,0.7l5.5-1.9l2.1,2.3l0,0l-0.1,3.5l2.6,1.7l-0.1,2.2l3.6,1.4l-0.6,1.3l2.8-0.5l2,2.4l3.5-0.5l2.4,2.3l3.1-0.7l1.3,2.1l4.1,0.9l2.3,4l0,0l0.2,2.5l-2.2,0.1l1.6,3.3l-1.8,5.5l-5.5-1.6l-7.2,3.7l2.5,0.5l-0.9,3l1.7,1.8l-2,1.8l5,2.8l-0.5,2.5l3.3,1.9l-1.9,2.7l2.1,2.7l-3.1,1.5l2.7,1.4l-1.6,3.1l2.7-0.8l4.5,1.9l-0.2,2.5l4.9,1.2l0.1,2.2l0,0l-1.8,5.3l0,0l-2.3-1.1l-4,1.6l-6.6-4.4l-1.4,1.5l-1.6-7.8l-5.9-1.1l-0.9-3l-0.3,1.5l-5-0.4l0,0l-4.3-9.7l-5.9-3.8l-2.6-3.9l-8.8-4.1l-0.1-5.5l4.3-1l-1.7-3.2l3-0.8l1.1-3.3l-1.4-4.1l2.3-0.5l-4.4-9.1l-3,0.1l1.2-3.5l3.9-3.2L168.8,299.6z"/>
                        <path id="FR-22" class="st3"
                              d="M81.2,143l0.3,2l5.3-1.9L87,146l3.1-0.2l-1.4,2.4l4.7,1.7l-0.5,2.3l5.3,4.5l-0.2,3.4l4.5,2.5l1.2,3.6l0.1-2.1l2.1,0.4l3.2-4.6l3.4-1.4l-0.6-1.4l2.9,0.8l4.1-3.5l1.3,1.6l-2.1,2.7l3.9-1.5l1.1,4.9l0.9-2.8l0.7,2.2l1.2-2.6l1,1.1l0,0l3.3,3.6l1.5-1.6l0,0l1,4.2l1.4-2.4l0,0l1.4,0.1l0.3,3.4l-1.7,2.4l1,5.9l-1.7,0.3l0.4,3.2l-4-0.3l-2.2,2.9l-3.2,0.1l-2.2,6.5l-2.1,0.6l0,0l-2.9,1.4l-2.8-3.9l-4.6,1l-0.5,5l-4.5,2.7l-0.2-6.1l-3.9,0.9l-0.8,1.8l-1.3-3.6l-8.1-1l-1.6-2.7l-3.2-0.2l-1,2.7l-5.5,1.5l-2.7-1.9l-3.4,1.6l-1.2-2.6l-5,0.1l0,0l1.9-0.3l-1.6-2.1l1.6-5.8l-1.4,0.1l-0.2-3.9l-2.1-0.6l2.3-3.6l-2.3-1.9l2.8-3.7l-4.2-3.6l-0.7-4l0,0l3.8-1.5l-0.5-7.8l1,0.9l2.1-3l4.4,2.5L81.2,143z"/>
                        <path id="FR-29" class="st3"
                              d="M2.8,168.4l1.6,1.5L0,170.6L2.8,168.4z M49.8,152l1.7,3.8l0.7-2.1l3.3,2.4l1-3.7l7.4,1.5l-0.6,2.3l0,0l0.7,4l4.2,3.6l-2.8,3.7l2.3,1.9l-2.3,3.6l2.1,0.6l0.2,3.9l1.4-0.1l-1.6,5.8l1.6,2.1l-1.9,0.3l0,0l-6.2,2.8l-0.8,1.9l4,7.9l4.5-0.2l2.7,2.7l2.1-1.8l1,1l-0.3,5.9l-2.1,0l-1.7,2.5l-1.6-1.4l-0.2,5.1l0,0l-8-2.6l-5.3,0.9l-4.2-6.4l-0.8,2.5L43,206l-1.9,3.7l-7.3,0l0.8-3.4l-3-6.8l-3.9-2.7l-9.2-2l0.9-1.6l12.4-2.6l5.3,0.4l0.7-3.3l-1.8-3.1l-7.2-2l-2.9,4.1l0.6-4.9l-3.5-1.9l2.4-0.2l-0.1-3L27,176l-0.1,3.6l1.5-1.7l-0.1,1.9l3.5-0.8l0.9,1.2l3.8-1.4l1.9,1.1l1.6-2.1l-4.8-0.1l1.5-1.1l-1.1-1.4l-5.4,1.8l2.4-3.5l-1-0.8l-7.6,3.6l-3.1-1.1l-3.8,1.6l-0.9-5.2l1.8-8.1l5.9-2.1l2.8-3.8l2.7,0.8l5.5-3.4l2,0.8l0,1.9l4-1.2l0.5-2.2l5.3,0.7l0.2-1.9L49.8,152z"/>
                        <path id="FR-35" class="st3"
                              d="M127.4,157.1l2.7,0.3l1.6,4.5l0,0l-1.5,1.6l-3.3-3.6l0,0l-1.1-2.1L127.4,157.1z M138.6,153.2l-1.2,3.8l1.5,2l11-1.3l0,0l3.5,9.1l2.8,1.8l2.8-0.5l4.2-4.7l7.6,2.2l0,0l1,11.2l-2.4,4.1l3.6,16.1l-5.8,2.4l-3.4,9.6l0,0l-0.4,2.1l0,0l-9.6-3.6l0.2,2l-6.2,2.4l-1.5,3.3l-8.4,0.1l-3.9,2.3l-0.8-1.4l-5.2,3.9l0,0l-1.3-5.6l3-1l-2.9-2l3.8-3.9l-0.8-2.2l-2.3,1.3l1.3-4.4l-2.8-4l-6-0.4l1.1-3l3.9-2.2l-3.6,0.8l-1.3-4.4l0,0l2.1-0.6l2.2-6.5l3.2-0.1l2.2-2.9l4,0.3l-0.4-3.2l1.7-0.3l-1-5.9l1.7-2.4l-0.3-3.4l-1.4-0.1l0,0l-3.3-7l4-3.2L138.6,153.2z"/>
                        <path id="FR-44" class="st3"
                              d="M128,219.9l5.2-3.9l0.8,1.4l3.9-2.3l8.4-0.1l1.5-3.3l6.2-2.4l-0.2-2l9.6,3.6l0,0l-0.3,2.8l2.4,0.6l2.4,6.3l5.5,1.9l-1.5,1.6l-5.4-0.6l0.7,3.8l7.9,1.1l1.9,5.7l-2.2,1.7l-13.1,1.9l-2.6,2.2l2.4-0.1l2.2,3.8l2-0.5l1.3,4.3l-3.5,5l5.5,2.2l-1.3,2.1l0,0l-5.1-3.4l-1.2,3.1l-3.2,0.1l0.6,4.5l-4.1,1.9l-0.4-6.3l-1.3-0.5l-2.2,1.5l2.3,8.1l-1.9,1.2l-7.2-1.4l-1-2.7l-6.4-2l-3.3-4.1l0,0l-3.1-4l-8.1-2.3l3.4-2.2l-0.2-5.8l-5.2,1.8l-3.3-2.7l-2.3,1.2l-4.7-1.9l1.6-2.9l-2.2-2.2l4.2-4.5l0,0l1.4-1.8l4.4,1l0.8-3.4l5.6,1.6l0.3-1.9l2.5-0.7L128,219.9z"/>
                        <path id="FR-49" class="st3"
                              d="M163.7,208.9l10.7,3l2-2l8.1,3.4l8.4-0.8l0.7-2.3l5.6,1.7l0,0l0.8,2.8l7.8-0.1l-1.8,2.1l3.3,3l1.8-1.8l9.9,5l1.7-2.1l1.8,0.4l0,0l-1.4,4.3l1.1,2.4l-2.9,6.2l1.2,1.1l-4.4,6.1l-0.9,7.2l0,0l-2.6,0.2l-1.2,4.5l-2.1-0.9l-0.7,2.2l0,0l-3-0.2l1.4-2.1l-1.8-0.4l-8.8,1l-3.5,2.3l0.9-1.7l-1.4-0.1l-2.6,1.2l0.5,1.9l-2.3,2.2l-7.8-0.5l-4.3,1.8l0,0l-3.5-2.6l-3.3,0.9l-3.8-1.5l0,0l1.3-2.1l-5.5-2.2l3.5-5l-1.3-4.3l-2,0.5l-2.2-3.8l-2.4,0.1l2.6-2.2l13.1-1.9l2.2-1.7l-1.9-5.7l-7.9-1.1l-0.7-3.8l5.4,0.6l1.5-1.6l-5.5-1.9l-2.4-6.3l-2.4-0.6l0.3-2.8l0,0L163.7,208.9z"/>
                        <path id="FR-53" class="st3"
                              d="M179.4,166l4,4.1l4-3.1l0.7,2.6l5.9-4.1l4.7-0.1l1.8,1.6l1.3-2.4l2.1,0.9l0.9-3.6l1.7,0.1l2.6,2.2l-1.2,1.1l1.1,3.4l4,0.4l-0.2,4.4l0,0l-4.2,2.6l0.5,8.4l-4.5,2.9l0.9,6.1l-4.6,1l2.7,5.2l-5.6,2.6l1.7,3.6l-3.1,1.6l2.4,1.8l0.2,2.6l0,0l-5.6-1.7l-0.7,2.3l-8.4,0.8l-8.1-3.4l-2,2l-10.7-3l0,0l3.4-9.6l5.8-2.4l-3.5-16.1l2.4-4.1l-1-11.2l0,0L179.4,166z"/>
                        <path id="FR-56" class="st3"
                              d="M80.3,234.8l7.7,4.5l-0.6,1.7l-6.8-1L80.3,234.8z M67.1,185.6l5-0.1l1.2,2.6l3.4-1.6l2.7,1.9l5.5-1.5l1-2.7l3.2,0.2l1.6,2.7l8.1,1l1.3,3.6l0.8-1.8l3.9-0.9l0.2,6.1l4.5-2.7l0.5-5l4.6-1l2.8,3.9l2.9-1.4l0,0l1.3,4.4l3.6-0.8l-3.9,2.2l-1.1,3l6,0.4l2.8,4l-1.3,4.4l2.3-1.3l0.8,2.2l-3.8,3.9l2.9,2l-3,1l1.3,5.6l0,0v6l-2.5,0.7l-0.3,1.9l-5.6-1.6l-0.8,3.4l-4.4-1l-1.4,1.8l0,0l-1.6-0.1l-0.3-2.6l2.5-0.4l-2.8-1.6l-12.7,2.3l-8.7-6.5l0,1.2l-4.1,0.2l1.4,5.9h-1.7l-1.1-8.2l-8.6-6.4l-4,0.7l-3-4l0,0l0.2-5.1l1.6,1.4l1.7-2.5l2.1,0l0.3-5.9l-1-1l-2.1,1.8l-2.7-2.7l-4.5,0.2l-4-7.9l0.8-1.9L67.1,185.6z"/>
                        <path id="FR-72" class="st3"
                              d="M212.8,173.5l4.7,0.2l3.6-5l6.5-1.4l3.4,3.5l0.4,6.4l4.1,0.7l1.9,3.8l4.3,1l0.6-1.9l3.5,4.7l2.5-0.4l0,0l4.9,3.4l-2.7,0.3l-0.4,1.8l0,0l-2,3.5l2,1.2l0.3,5.5l-1.6,3.2l-2.1-0.8l0.6,3.6l-3.1,4.6l-5,3.3l1.4,1.1l0,0l-6.9,4.7l-2.7-1.5l-0.2,4.6l-6.2-2.5l0,0l-1.8-0.4l-1.7,2.1l-9.9-5l-1.8,1.8l-3.3-3l1.8-2.1l-7.8,0.1l-0.8-2.8l0,0l-0.2-2.6l-2.4-1.8l3.1-1.6l-1.7-3.6l5.6-2.6l-2.7-5.2l4.6-1l-0.9-6.1l4.5-2.9l-0.5-8.4L212.8,173.5z"/>
                        <path id="FR-79" class="st3"
                              d="M178.1,259.9l4.3-1.8l7.8,0.5l2.3-2.2l-0.5-1.9l2.6-1.2l1.4,0.1l-0.9,1.7l3.5-2.3l8.8-1l1.8,0.4l-1.4,2.1l3,0.2l0,0l0.7,4.7l2.2,0.2l-0.1,6.5l2.9,1.2l-3.4,2l2.3,0.4l-1.2,2.8l2.5,2.1l-4.4,6.3l2.8-0.9l-0.5,1.7l2.3,1.4l-2.1,1.4l-1.6,5.2l1.5,0.9l-0.6,4.1l2.4,1.6l-0.8,2.8l2.6,1.3l2-2.6l2.2,1.1l-2.9,8.6l4.6,1.7l-1.1,3.8l0,0l-3.9-0.6l-3.3,3.2l-2.3-0.1l-0.1,3.7l-2.7,1.4l0,0l-2.3-4l-4.1-0.9l-1.3-2.1l-3.1,0.7l-2.3-2.3l-3.5,0.5l-2-2.4l-2.8,0.6l0.6-1.3l-3.6-1.4l0.1-2.2l-2.6-1.7l0.1-3.5l0,0l4.2-0.8l4.5-4.1l-2.8-1.7l-1.5,1l1.4-3.4l-1.6-3.9l1.9-1.7l-0.5-4.4l-1.8-1.1l1-1.4l-1.8-1.4l1-0.8l-2.9-4.1l1.2-2.5l-5.5-4.5l1-2.2L178.1,259.9z"/>
                        <path id="FR-85" class="st3"
                              d="M121.7,256.7l0.9,3.2l3.1,1.5l0.3,3.3l-2.4-3.9l-3.8-2l-0.3-2L121.7,256.7z M133,256.6l3.3,4.1l6.4,2l1,2.7l7.2,1.4l1.9-1.2l-2.3-8.1l2.2-1.5l1.3,0.5l0.4,6.3l4.1-1.9l-0.6-4.5l3.2-0.1l1.2-3.1l5.1,3.4l0,0l3.8,1.5l3.3-0.9l3.5,2.6l0,0l3.5,3.3l-0.9,2.2l5.5,4.5l-1.2,2.5l2.9,4.1l-1,0.8l1.8,1.4l-0.9,1.4l1.8,1.1l0.5,4.4l-1.9,1.7l1.6,3.9l-1.4,3.5l1.5-1l2.8,1.7l-4.5,4.1l-4.2,0.8l0,0l-2.1-2.3l-5.5,1.9l-1.2-0.7l1.3-2.9l-7.7,3.3l0,0l-3.6,0.1l-0.2,2.7l-4.6-3.8l0.8,2.6l-2-3.1l-5.2,0l-1.9-3.7l-5.6-1l-6.8-4.5L138,282l-11.8-12.8l-0.4-4.1L133,256.6z"/>
                        <path id="FR-86" class="st3"
                              d="M210.9,254.5l0.7-2.2l2.1,0.9l1.2-4.5l2.6-0.2l0,0l1.1,2.7l4.2,0.2l-0.1,3.4l5.2,0.5l0.9,7.3L240,261l-1.3-3l5.1,2l0.5,4.3l3.8,3.2l3,6.1l0,0l2.5,3.1l-0.7,5.8l9.8,5.7l0.2,3.4l2.5,0.9l-1.4,2.9l0,0l-2,2.2l-3.4-0.9l-1.8,5l-4.2-0.5l-2.2,3.5l-1.6,0l0.6,6.2l0,0l-4.6-0.7l-1.1,2.6l-3.8,1.4l-4.3-3.9l-2,2.1l1.6,2.1l-2.2,1l-9.7-2.8l0,0l1.1-3.8l-4.6-1.7l2.9-8.6l-2.2-1.1l-2,2.6l-2.6-1.3l0.8-2.8l-2.4-1.6l0.6-4.1l-1.5-0.9l1.6-5.2l2.1-1.4l-2.3-1.4l0.5-1.7l-2.8,0.9l4.4-6.3l-2.5-2.1l1.2-2.8l-2.3-0.4l3.4-2l-2.9-1.2l0.1-6.5l-2.2-0.2L210.9,254.5z"/>
                        <text transform="matrix(1 0 0 1 122 216)" class="map-admin__zone-label">Ouest</text>
                    </g>
                </a>
                <a href="#" class="map-admin__zone" :class="{ active:  currentZone && currentZone.id === String(5)}" @mouseover="setCurrentZoneById(5)" @mouseout="setCurrentZoneById()" @click.prevent="goToAnchor(currentZone.link)">
                    <g>
                        <path id="FR-03" class="st4"
                              d="M309.8,293.4l3.3-5.8l7.3-0.1l3.2-2.1l-1.9-6.4l2.4,0l-0.1-1.9l3.8-3.3l1.9,1.7l3.5-0.5l3.1-4.1l4.6-0.1l0,0l7.1,7.2l2.8-2.5l3.2,2l3.1-1.8l2.6,3.8l3.3-2.3l-0.5-2l2-0.1l0-2.5l1.4,1l0,0l4.7,12.7l4,0.4l1.1,2.6l3.6-0.2l1.9,1.8l0.2,8.1l-4.4,3.1l0,0l-5.5,2.2l2.6,14.3l-5.1,1.5l-0.6,2.6l0,0l-4.2-5.6l-5.3,0.9l-0.1-3.1l-1.8-0.9l-3.6,1.7l-8.4-1.5l-1.6-2.7l-3.2,0.7l-4.2-4.5l1.4-3.8l-1.1-0.6l-3.6,0.2l-0.9,3l-3.2-1.6l-3.9,6.2l-3-1.5l0,0l-3.5-8.2l-7.5-3.8l1.3-1.6l-2-0.7L309.8,293.4z"/>
                        <path id="FR-15" class="st4"
                              d="M319.3,349.5l7.5,2.4l1.1,3.3l7-0.1l4.8,5.5l4.6-3.6l0,0l-1.2,3.7l5.8,0.7l2.8,9.2l3,1l-3.2,0l3.2,7.8l0,0l-3.1,2.6l-1.8-0.5l-2.1,4.3l-2.1-2.3l-1.6,1l-5,14.1l0,0l-5.1-13.4l-3.6,1.1l-1.5-5l-5.5,5.6l-1.9,7.3l-3.2,4l-5.5,0.6l-2.4-1.6l-3.3,1.1l-0.3,1.9l0,0l-3.2-4.6l1.7-5.4l-3.3-4.9l-1.2-6l0,0l3.4-1.5l-1.8-3.4l3.1-1.3l0.3-3.2l2.2-1.8l-1.6-3.1l6.9-6.8l-0.2-4.6l5.2,2.6l1.8-0.7l-1.5-2.2L319.3,349.5z"/>
                        <path id="FR-18" class="st4"
                              d="M308.1,220.6l2.1-0.5l3.4,2.7l2.7-1.5l2.2,2.5l3-0.2l4.4,5.4l2.3-0.6l0.3-2.7l3.1,2.7l2.8-1.6l0,0l2.3,5.1l-2.6,6.1l4.7,4.7l1.5,12l2.5,2.5l-1.9,13.9l0,0l-4.6,0.1l-3.1,4.1l-3.5,0.5l-1.9-1.7l-3.8,3.3l0.1,1.9l-2.4,0l1.9,6.4l-3.2,2.1l-7.3,0.1l-3.3,5.8l0,0l-4.7-0.3l0,0l-0.7-2l2.2-1.5l-1.9-4.4l1.3-5.2l-4.2-4.2l-0.8-2.3l2.1-1.8l-3.1-3.6l1.4,0.3l3.3-4.7l-3.5-1.3l1.5-3.8l-3.3-3.1l0.9-3.6l-1.9,0.8l-0.4-2l-4.9,1.5l-4.2-1.7l2.8-3l-0.1-2.5l0,0l2.5,0.4l-0.3-2.6l1.9-2l3.4,1.7l5.5-2.2l-2.5-4.1l0.8-3.4l3.1-1l1.7,1.9l0.7-5.2l-2.1,0.3l0.1-3.6l-2.9-0.3l-0.4-2L308.1,220.6z"/>
                        <path id="FR-19" class="st4"
                              d="M293.9,336.5l6.3-4l1.4,2.1l3.2-0.5l1.3,2.2l3.1,0.4l-0.1,1.6l9.3-4.4l0,0l1.6,4.7l-2.9,3.7l2.3,2.5l-0.4,4.5l0,0l-0.9,3.5l1.5,2.2l-1.8,0.7l-5.2-2.6l0.2,4.6l-6.9,6.8l1.6,3.1l-2.2,1.8l-0.3,3.2l-3.1,1.3l1.8,3.4l-3.4,1.5l0,0l-4.7,1.4l-1.6-1.5l-4.4,3.2l-6.5-6.2l-4.5-1.1l-3.7,1.6l0,0l-2.9-5l1.4-1.2l-4.3-0.5l-2.3-1.8l1.5-1.5l-2.6-0.9l2.1-2.2l-2.1-1.8l0.4-2.9l3.7-3.5l-2.8-1l1.4-2.1l-1.7-0.6l0,0l1.4-2.7l2.7,1.4l5.3-5.8l4.4,0.5l8.4-7.4l3.6,1L293.9,336.5z"/>
                        <path id="FR-23" class="st4"
                              d="M273.9,297.9l4.6-4.8l0.8,2.1l2.7-1.7l0.8,2l2.4-1.8l1.8,1.7l2.2-4l2.3,1.8l13.7,0.1l0,0l4.7,0.3l0,0l0.3,2.3l2,0.7l-1.3,1.6l7.5,3.8l3.5,8.2l0,0l-0.7,3.4l2.5,7.1l-4.8,6.1l-4.3,2.2l4.3,5.3l0,0l-9.3,4.4l0.1-1.6l-3.1-0.4l-1.3-2.2l-3.2,0.5l-1.4-2.1l-6.3,4l0,0l-0.6-5.8l-4.5-2.2l-0.1-2l-4.6,1.9l-2.9-2.8l2.1-0.6l-0.2-2l-5.4-0.4l2.8-2.8l-1.8-1.8l0.3-4.2l-2.3-1.5l0.5-2.2l-5.1-4.4l2.6-3.4L273.9,297.9z"/>
                        <path id="FR-28" class="st4"
                              d="M277.5,138.5l4,6.4l0.2,11.1l7.6,6.8l0.6,5.4l2.8,1.6l2.2-1.1l0,0l0.2,3.2l2.1,0.3l-0.8,5.7l1.5,1.5l0,0l-0.9,7l-2.8,0.8l-0.9,3.8l-5.7,2.1l-5-0.4l-1.2,2.4l-3.4,0.2l0.4,2.8l0,0l-3-1.5L272,200l-7.2-0.8l-3.5-7l-5-0.6l2-2.6l-3.8,2.1l-4.5-0.3l0,0l0.4-1.8l2.7-0.3l-4.9-3.4l0,0l1.3-1.2l-2.5-6.8l6-3.1l2.6-4.2l-1.7-2.3l1.3-2.9l-6.1-5.5l-0.1-3.6l0,0l2.5-2.9l5.7-0.6l2-2.1l2.1,0.8l0.3-2.7l8.6,1.9l1.8-1.3l-0.4-3.4l4.1-2.4l-0.5-3.2L277.5,138.5z"/>
                        <path id="FR-36" class="st4"
                              d="M270.2,247.2l5.1-3.3l3.4,1.2l-0.3-2.2l3.6-1.4l2.3,1.2l2-1.2l5.2,3.5l0,0l0.1,2.5l-2.8,3l4.2,1.7l4.9-1.5l0.4,2l1.9-0.8l-0.9,3.6l3.3,3.1l-1.5,3.8l3.5,1.3l-3.3,4.7l-1.4-0.3l3.1,3.6l-2.1,1.8l0.8,2.3l4.2,4.2l-1.3,5.2l1.9,4.4l-2.2,1.5l0.7,2l0,0l-13.7-0.1l-2.3-1.8l-2.2,4l-1.8-1.7l-2.4,1.8l-0.8-2l-2.7,1.7l-0.8-2.1l-4.6,4.8l0,0l-2.6-3.2l-2.1,1.7l-5.2-0.8l0,0l1.4-2.9l-2.5-0.9l-0.2-3.4l-9.8-5.7l0.7-5.8l-2.5-3.1l0,0l6-0.6l-1.1-2.8l2.9-11.6l4.7-2.7l3.6,1.1l4.6-5.8L270.2,247.2z"/>
                        <path id="FR-37" class="st4"
                              d="M240.7,215.9l10.4,0.3l-0.2,5.6l2.4-2l3,0.8l0.2,2.2l1.5-1.5l1.8,2.2l-1.4,2.5l1.7,4.2l2,0.8l-2,1.2l1.6,2.4l-0.8,5.2l2.4,2l3.4-0.8l3.5,6.2l0,0l1.5,4.1l-4.6,5.8l-3.6-1.1l-4.7,2.7l-2.9,11.6l1.1,2.8l-6,0.6l0,0l-3-6.1l-3.8-3.2l-0.5-4.3l-5.1-2l1.3,3l-11.4,1.6l-0.9-7.3l-5.2-0.5l0.1-3.4l-4.2-0.2l-1.1-2.7l0,0l0.9-7.2l4.4-6.1l-1.2-1.1l2.9-6.2l-1.1-2.4l1.4-4.3l0,0l6.2,2.5l0.2-4.6l2.7,1.5L240.7,215.9z"/>
                        <path id="FR-41" class="st4"
                              d="M250,190.7l4.5,0.3l3.8-2.1l-2,2.6l5,0.6l3.5,7l7.2,0.8l3.3-3.2l3,1.5l0,0l1.8-0.5l-1.8,3.6l2.8,3.3l-2.7,3.6l3.1,4.8l-0.9,1l2.2-2l3.4,1.5l1.1,4.5l2.2,1.5l2.7-3.4l4.4,2.2l9.9-1.3l1.5,3.6l0,0l-4.9,2.3l0.4,2l2.9,0.3l-0.1,3.6l2.1-0.3l-0.7,5.2l-1.7-1.9l-3.1,1l-0.8,3.4l2.5,4.1l-5.5,2.2l-3.4-1.7l-1.9,2l0.3,2.6l-2.5-0.4l0,0l-5.2-3.5l-2,1.2l-2.3-1.2l-3.6,1.4l0.3,2.2l-3.4-1.2l-5.1,3.3l0,0l-3.5-6.2l-3.4,0.8l-2.4-2l0.8-5.2l-1.6-2.4l2-1.2l-2-0.8l-1.7-4.2l1.4-2.5l-1.8-2.2l-1.5,1.5l-0.2-2.2l-3-0.8l-2.4,2l0.2-5.6l-10.4-0.3l0,0l-1.4-1.1l5-3.3l3.1-4.6l-0.6-3.6l2.1,0.8l1.6-3.2l-0.3-5.5l-2-1.2L250,190.7z"/>
                        <path id="FR-43" class="st4"
                              d="M344.3,356.9l2.8,0l5-4.1l5,1.3l3.4-1.8l4.9,5.3l2.2-2.7l4.4,2.1l1.1-2.5l4,2l0,0l0.4,1.4l2.8-2.5l1.7,2.8l5.8-3.6h3.1l1.6,1.8l3.1-0.3l0.1,5.2h3.6l1.9,2.3l0,0l-1.1,6.9l-3.5-1.5l1,2.3l-2.2,2.2l1.5,1.6l-3.6,1.3l0.7,2.7l-3.7,0.6l-2.8,5.3l-5.1,0l-0.3,2.3l-3.3,0.4l-1.1,3.4l-2.6,1.4l0,0l-4.9-5.5l-3.2,0.5l-1.5-3.1l-1.5,0.3l-0.2,2.9l-4.6,1l-3.1-8.6l-1.8-0.8l0,0l-3.2-7.8l3.2,0l-3-1l-2.8-9.2l-5.8-0.7L344.3,356.9z"/>
                        <path id="FR-45" class="st4"
                              d="M297.9,179.4l7-0.8l1.6-2.8l1.8,2.8l3.5-2.1l3.1,1.1l0,0l0.8,3.5l3.4,1.3l0.7,2.4l-3.3,4.7l10.9,0l2-1.4l-0.7-1.3l2.6-0.1l0.9,2.4l4.9-2.1l0,0l3.8,1.9l0.8,3.7l3.1,2.7l0.1,3.5l-4.9,4.1l0.7,7.4l-7.2,1.8l-0.2,2.5l3.2,2.2l2.1,6.9l0,0l-5,1l0.7,1.9l0,0l-2.8,1.6l-3.1-2.7l-0.3,2.7l-2.3,0.6l-4.4-5.4l-3,0.2l-2.2-2.5l-2.7,1.5l-3.4-2.7l-2.1,0.5l0,0l-1.5-3.6l-9.9,1.3l-4.4-2.2l-2.7,3.4l-2.2-1.5l-1.1-4.5l-3.4-1.5l-2.2,2l0.9-1l-3.1-4.8l2.7-3.6l-2.8-3.3l1.8-3.6l-1.8,0.5l0,0l-0.4-2.8l3.4-0.2l1.2-2.4l5,0.4l5.7-2.1l0.9-3.8l2.8-0.8L297.9,179.4z"/>
                        <path id="FR-63" class="st4"
                              d="M321.7,310l3,1.5l3.9-6.2l3.2,1.6l0.9-3l3.6-0.2l1.1,0.6l-1.4,3.8l4.2,4.5l3.2-0.7l1.6,2.7l8.4,1.5l3.6-1.7l1.8,0.9l0.1,3.1l5.3-0.9l4.2,5.6l0,0l2.6,2.6l-2.7,5.6l12.3,17.5l-0.9,3.5l-3,2.1l0.2,2.5l0,0l-4-2l-1.1,2.5l-4.4-2.1l-2.2,2.7l-4.9-5.3l-3.4,1.8l-5-1.3l-5,4.1l-2.8,0l0,0l-4.6,3.6l-4.8-5.5l-7,0.1l-1.1-3.3l-7.5-2.4l0,0l0.4-4.5l-2.3-2.5l2.9-3.7l-1.6-4.7l0,0l-4.3-5.3l4.3-2.2l4.8-6.1l-2.5-7.1L321.7,310z"/>
                        <path id="FR-87" class="st4"
                              d="M264,295.5l5.2,0.8l2.1-1.7l2.6,3.2l0,0l0.9,4.5l-2.6,3.4l5.1,4.4l-0.5,2.2l2.3,1.5l-0.3,4.2l1.8,1.8l-2.8,2.8l5.4,0.4l0.2,2l-2.1,0.6l2.9,2.8l4.6-1.9l0.1,2l4.5,2.2l0.6,5.8l0,0l-1,2l-3.6-1l-8.4,7.4l-4.4-0.5l-5.3,5.8l-2.7-1.4l-1.4,2.7l0,0l-5.5-1.9l1.9-3l-3.4-0.3l-2.8-4.7l-5,0.6l-1.2-1.3l-2.4,2.8l-2.5-2.5l1-2.9l-6-2.5l0,0l3.5-3.1l-0.1-2.5l2.9,0.8l1.2-8.4l2,0.9l3.4-2.4l-0.6-3.1l-4.7-2.5l0.4-4.6l0,0l-0.6-6.2l1.6,0l2.2-3.5l4.2,0.5l1.8-5l3.4,0.9L264,295.5z"/>
                        <text transform="matrix(1 0 0 1 260 316)" class="map-admin__zone-label">Massif central</text>
                        <text transform="matrix(1 0 0 1 287 336)" class="map-admin__zone-label">Centre</text>
                    </g>
                </a>
                <a href="#" class="map-admin__zone" :class="{ active:  currentZone && currentZone.id === String(6)}" @mouseover="setCurrentZoneById(6)" @mouseout="setCurrentZoneById()" @click.prevent="goToAnchor(currentZone.link)">
                    <g>
                        <path id="FR-21" class="st5"
                              d="M386.1,250.9l-0.1-1.3l3-0.3l0,0l-0.5,2.3L386.1,250.9z M393.4,201.8l0.9-2.2l10-0.4l-0.8-2.4l2-1.5l5,0.6l0,0l3.7,0.9l-0.4,2.7l3.4,1.4l-1.4,1.9l3-0.8l3.8,6.3l-3.2,3.2l1.8,0.3l0.4,4.7l2.5-1.5l3.7,3.9l2.5-2.1l-0.2,1.7l3.5,1.8l-0.3,2.8l2.3-2l2.7,0.3l0,0l2.3-1.6l2.1,1.5l0.4,4.9l-5,3.9l3,0.9l-0.3,3.5l2.8,0.2l-1.3,4.4l2.1,0.8l0,0l-3.3,9.8l-2.2,3.7l-4.2,2l1.4,2.2l-2.6,2l0,0l-6.6,2.1l-2-2.2l-6.4,0.9l-8.6,4l-5.8-5.6l0.3-1.7l-5.3-1.1l-1-2.7l-2,1.2l-0.3-2.3l-3-0.4l-2.6-2.9l0,0l0.8-3.2l-2-2.8l-1.9,1l0.4-7.1l-1.5,0.9l0,0l-1.7-4.2l2.4-2.5l-0.3-4.1l5.6-8.9l-0.9-1.1l2.3-0.2l-1.9-2.6l3.9-2l-0.1-5.6l-2.5,0.5l-1-2.1l2.7-1.9L393.4,201.8z"/>
                        <path id="FR-08" class="st5"
                              d="M415.9,61l2.5,1.1l-3.2,4.6l0.9,1.7l-2.1,4.5l4.1,3.8l-1.7,2.8l0.2,4.7l6-0.4l4.1,2.4l3,4.4l4.3-0.2l2.5,2.7l-0.7,2.7l3.4-0.3l0,0l-5.5,4.8l-6.2-3.2l-2.2,5.8l2.2,2.7l-1.4,3.5l1,1.6l-3,2.7l0.9,3.9l-4.1,2.3l0,0l-1.1-1.8l-1.9,0.2l-0.4,2.2l-7.3-1.9l-2.3,1.6l-1.5-3.8l-6.3,1.2l-2.8-3.3l-3-0.1l-2.5-3.6l-8.3-1.5l0,0l1.2-10.5l-2.3-3.2l3.8-0.7l0.5-2.9l5.1-5.1l-1.8-1.5l2-5.7l-0.9-5.8l0,0l3.2-0.7l5.7,2l10-3.9l0.6-6.2L415.9,61z"/>
                        <path id="FR-10" class="st5"
                              d="M362.9,158.8l3.4,4.9l7.7,1.4l1.6-1.4l-0.5-2.7l2,0.4l7.5-7.9l2.1,0.9l7-1.8l1.4,2.3l-0.7,4l3.5,3.4l5.9,2.2l2-1.7l3.2,1.4l0,0l-1.7,4.4l3.2,4.4l5.6,3l-1.1,1.2l2,3.5l-1.1,10l-4.3-0.8l-3.1,2.4l2.7,2.1l-0.7,1.5l0,0l-5-0.6l-2,1.5l0.8,2.4l-10,0.4l-0.9,2.2l0,0l-2-0.3l-0.8-2.6l-1,1.9l-1.5-1.1l-2.4,2.1l-2.1-1.2l-0.6,1.3l-6.2-0.2l0.8-3.1l-0.8-1.4l-1.8,1.2l0.8-2l-3.4-6.4l-2.1-0.9l0.4-2.1l-3.4,1.6l-1.5-3.1l-2.1,0.3l1.7-5.2l-4.8-6.1l-3.1,0.5l-0.6-2l0,0l-1.3-5.6l2.3-0.7l-1.3-2.3l3.1-0.9l-0.7-1.8L362.9,158.8z"/>
                        <path id="FR-25" class="st5"
                              d="M451.8,242.3l8.2-2.8l1.2-2.1l2.1,1.2l5.6-2.2l0.2-2.2l2.1,0.4l1.5-2.6l1.5,1.1l3.9-5.7l3.2-1l2.5,2.2l1.2-1.5l2.4,1.3l1-3.3l2.8,0.5l0.5-2.7l4.5,2.6l1.6-1.5l0,0l3.9,0.8l1.8,2.2l-1.5,2.5l1.4,2.5l0,0l-2.5,4.9l5.7-1.2l1.6,2.4l-4.7,2.9l0.5,2.7l-10.5,9.8l0.7,1.9l-11.9,7.4l1.3,2.4l-0.5,6.9l-13.5,11.2l0.5,1.9l0,0l-3.7-3l2.3-2.7l-1.5-2.3l5.6-4.5l-2.3-3.5l-4.9-1.7l-4.6-10.1l-3.1,0.9l-1.6-2.7l-2.7,1.5l-0.3-2.1l2,0l-0.6-2.4l2.4-2.8L451.8,242.3z"/>
                        <path id="FR-39" class="st5"
                              d="M444.2,239.9l2.4,3.1l5.1-0.7l0,0l5.2,7.6l-2.4,2.8l0.6,2.4l-2,0l0.3,2.1l2.7-1.5l1.6,2.7l3.1-0.9l4.6,10.1l4.9,1.7l2.3,3.5l-5.6,4.5l1.5,2.3l-2.3,2.7l3.7,3l0,0l-3,8.5l0,0l-8.1,9.1l-6,0.2l-0.5-2.9l-2.9-2.1l-4.3,4.6l-2.7,0.2l0.1-3.1l-2.1-1.9l-0.8,2.2l-1.1-4.3l-3.2-1.9l0.6-2l0,0l4.3-1.8l0.2-1.5l-2.6-1l0.1-3.5l1.8-0.2l1.5-3.4l-3.4-5.5l1.3-2.5l-2.7-2.7l5.7-0.7l-0.1-1.6l-2.6-2.3l-2.9,0.3l-3.2-3.4l0.2-2.2l0,0l2.6-2l-1.4-2.2l4.2-2l2.2-3.7L444.2,239.9z"/>
                        <path id="FR-51" class="st5"
                              d="M359.8,144l8-10.2l-1.1-1.8l-2.7,0.4l2.2-2.5l-1.6-2.7l0.9-1.9l5.2-0.5l-3.9-3.4l-0.4-6.4l5.5-2.7l3,0.7l0.2-2.2l2.8-1.7l4.7,3l0.5-2.9l0,0l8.3,1.5l2.5,3.6l3,0.1l2.8,3.3l6.3-1.2l1.5,3.8l2.3-1.6l7.3,1.9l0.4-2.2l1.9-0.2l1.1,1.8l0,0l1.7,1.7l-2.2,1.6l2.7,8l-1.7,2.4l2.9-0.1l-1.7,2.5l1.9,0.6l-5.2,4.7l1,3.5l-1.9,2.8l5,3.6l-0.9,3.6l0,0l-8.6,0.9l-0.4,1.1l3.4,1.1v1.4l-3.8,1.1l1.5,4.1l-5.4-0.1l0,0l-3.2-1.4l-2,1.7l-5.9-2.2l-3.5-3.4l0.7-4l-1.4-2.3l-7,1.8l-2.1-0.9l-7.5,7.9l-2-0.4l0.5,2.7l-1.6,1.4l-7.7-1.4l-3.4-4.9l0,0l-1-1.9l-3.5,0.7l0.7-6.4l-3.1-1.3l2-2l-1.7-1.2l3.5-0.3L359.8,144z"/>
                        <path id="FR-52" class="st5"
                              d="M409,164.1l5.4,0.1l-1.5-4.1l3.8-1.1v-1.4l-3.4-1.1l0.4-1.1l8.6-0.9l0,0l0.5,4.4l2.3-1.2l0.6,2.3l2,0l3.8,4.2l4.8,1.2l5.8,5.5l0,0l-3.1,1.8l2,0.7l-0.6,3l4.1-1l8.6,9.6l-2.1,0.9l-0.4,4.4l-1.8,1.4l6.2,3.2l0.4,5l2.5-1.4l1.6,3.1l0,0l-2.4,4.5l-2.8-0.4l-0.8,2.4l-2.1-0.1l0,8.4l-3.9,0.8l-1.2-2.2l-1.7,2.1l-4.8-0.1l-1.6,4.3l0,0l-2.7-0.3l-2.3,2l0.3-2.8l-3.6-1.8l0.2-1.7l-2.5,2.1l-3.7-3.9l-2.5,1.5l-0.4-4.7l-1.8-0.3l3.2-3.2l-3.8-6.3l-3,0.8l1.4-1.9l-3.4-1.4l0.4-2.7l-3.7-0.9l0,0l0.7-1.5l-2.7-2.1l3.1-2.4l4.3,0.8l1.1-10l-2-3.5l1.1-1.2l-5.6-3l-3.2-4.4L409,164.1z"/>
                        <path id="FR-54" class="st5"
                              d="M455.1,99.2l4.5,3.9l0,0l2.2,2l-1.4,3.8l2.8,4l-1.3,1.2l2.4,0.9l1.5,4.5l-2.7,2.9l2.3,1.8l-2.1,1.3l0.9,2.1l-3.3,1.3l5,5l-0.1,2.2l3.1,0.6l0.7,2.1l7.4,0.9l-1.1,4.7l2.3,0.8l-1.6,0.6l2.2,0.8l0.1,2.1l3.1-0.6l5.4,2.1l1.5,2.6l2.6,0.6l0.2,2.4l0.8-1.1l6.9,4l3.2-0.9l3.3,1.5l-0.7,1.1l3.4,3.6l0,0l1.7,1.4l0,0l-13.9,7.8l-5.7-2.5l-1-2.8l-2.2,2l0.5,1.6l-1.9-1l-6,2.5l-3.9-2.7l-2.6,2.3l-3.4-0.7l-1.4,3.4l-1.2-1.9l-0.7,1.5l-4.5,0.6l-0.5-2.9l-1.9-0.3l1.1-2.1l-3.1,1.5l2-5.3l-5.8-0.1l0,0l0.5-2.7l-2.5-3l3.6-2.3l-1.8,0.2l0.3-5l-2.1-2.8l2.6-3.1l0.4-5.8l-1.8-1.9l4.2-2.2l-2-2.1l2.2-3.5l-2-1.4l0.7-2.7l-3.1-1.1l0.8-4.6l-1.7,0.1l-0.1-4.4l2-2.7l-1.6-0.2l-0.9-3.4l1-2.2l-4.2-2.6l-6.2,3.5l-1.4-1.1l2.4-1.3l-3.2-4.2l1.8-0.7l0,0l3.5-2l2.3,1.5l1.3-2.7l4.3,0.7L455.1,99.2z"/>
                        <path id="FR-55" class="st5"
                              d="M440.3,96.6l2.1,6.5l0,0l-1.8,0.7l3.2,4.2l-2.4,1.3l1.4,1.1l6.2-3.5l4.2,2.6l-1,2.2l0.9,3.4l1.6,0.2l-2,2.7l0.1,4.4l1.7-0.1l-0.8,4.6l3.1,1.1l-0.7,2.7l2,1.4l-2.2,3.5l2,2.1l-4.2,2.2l1.8,1.9l-0.4,5.8l-2.6,3.1l2.1,2.8l-0.3,5l1.8-0.2l-3.6,2.3l2.5,3l-0.5,2.7l0,0l-1.9,2.3l-3.2-0.8l-0.9,2.9l-6.3,0.3l0,0l-5.8-5.5l-4.8-1.2l-3.8-4.2l-2,0l-0.6-2.3l-2.3,1.2l-0.5-4.4l0,0l0.9-3.6l-5-3.6l1.9-2.8l-1-3.5l5.2-4.7l-1.9-0.6l1.7-2.5l-2.9,0.1l1.7-2.4l-2.7-8l2.2-1.6l-1.7-1.7l0,0l4.1-2.3l-0.9-3.9l3-2.7l-1-1.6l1.4-3.5l-2.2-2.7l2.2-5.8l6.2,3.2l5.5-4.8l0,0L440.3,96.6z"/>
                        <path id="FR-57" class="st5"
                              d="M474.4,102.4l12.3,4.9l2.4,4.2l-1.4,1.2l4.3,4.3l2.9,7.3l4,0.7l0.1-3.8l4.2-0.6l4,2l0.7,4.9l2-2.6l2.4,2.2l5.7,0.3l3-3.6l3.3-0.8l-0.4,1.2l2.4-0.3l1.7,4.8l4.2,2.5l0,0l-4.1,7.6l-3.5-2.3l-5.2,1.6l-1.3-1.9l-7-2l-0.4-4l-2.1,0.3l-1.5,6.9l-3.7,1.4l0.4,2.5l5.8,2l-1.8,1.2l1.1,3.7l4.4-4.1l5.4,5.1l-3,4.7l2.6,2l-1.6,4.4l-4.1,3.9l-3.7-0.5l0,0l-3.4-3.6l0.7-1.1l-3.3-1.5l-3.2,0.9l-6.9-4l-0.8,1.1l-0.2-2.4l-2.6-0.6l-1.5-2.6l-5.4-2.1l-3.1,0.6l-0.1-2.1l-2.2-0.8l1.6-0.6l-2.3-0.8l1.1-4.7l-7.4-0.9l-0.7-2.1l-3.1-0.6l0.1-2.2l-5-5l3.3-1.3l-0.9-2.1l2.1-1.3l-2.3-1.8l2.7-2.9l-1.5-4.5l-2.4-0.9l1.3-1.2l-2.8-4l1.4-3.8l-2.2-2l0,0l3.2,0.3l0.6,2.7l2.6,0.3l5.5-4L474.4,102.4z"/>
                        <path id="FR-58" class="st5"
                              d="M338.7,223.7l1.9,0.8l3.4-1.9l2.6,4.2l7.6,3l1.9-2.2l4,0.7l0.5-4.1l3.7,6.4l3.6,0.4l2.1,3.2l2.5-0.4l1.3,1.6l2-3.4l0.2,4.2l3.2-1.8l2.2,5.1l4.3-1.8l0,0l1.5-0.9l-0.4,7.1l1.9-1l2.1,2.8l-0.8,3.2l0,0l-0.9,0.3l0,0l-3,0.3l0.1,1.3l0,0l-2.8,0.7l0.3,6l-2.6,1.7h1.9l0,4.6l2.6,2.6l-2.3,1.8l0.5,3.6l-10.7,4.6l-2.5-3.1l-4.5,0.2l0,0l-1.4-1l0,2.5l-2,0.1l0.5,2l-3.3,2.3l-2.6-3.8l-3.1,1.8l-3.2-2L348,278l-7.1-7.2l0,0l1.9-13.9l-2.5-2.5l-1.5-12l-4.7-4.7l2.6-6.1l-2.3-5.1l0,0l-0.7-1.9L338.7,223.7z"/>
                        <path id="FR-67" class="st5"
                              d="M508.9,163.9l3.7,0.5l4.1-3.9l1.6-4.4l-2.6-2l3-4.7l-5.4-5.1l-4.4,4.1l-1.1-3.7l1.8-1.2l-5.8-2l-0.4-2.5l3.7-1.4l1.5-6.9l2.1-0.3l0.4,4l7,2l1.3,1.9l5.2-1.6l3.5,2.3l4.1-7.6l0,0l6.5-0.8l3,2l2.8-1.5l12.4,5.8l-5.9,10.3l-4.9,2.8l-5.5,7.3l-4.4,15.4l0.5,4.2l-2.1,1.6l-4.8,11.2l0,0l-4.5-2.4l0.3-2.7l-6.7-3l0.7-1.2l-1.6,0.1l-0.7-2.2l-3.2-0.3l0,0l-0.8-1.8l-4.3-0.8l1.8-10.1l0,0L508.9,163.9z"/>
                        <path id="FR-68" class="st5"
                              d="M514,177.9l3.2,0.3l0.7,2.2l1.6-0.1l-0.7,1.2l6.7,3l-0.3,2.7l4.5,2.4l0,0l-0.3,5.4l2.2,3.8l-2.7,5.7l-0.4,9l-1.5,2.2l3,7l-3.6,2.7l1.3,0.7L526,229l-2.4-0.8l1,1.5l-2.9,2.4l-5.9,0.7l-3-1.4l1.3-3.1l-2.9-0.6l0,0l-1.9-5.5l-3.3-0.6l1.3-7.4l-7.1-3.7l-1.2-2.4l0,0l3.5-1.7l-1-2.5l2-6.7l5.5-6.8l-0.7-2.2L514,177.9z"/>
                        <path id="FR-70" class="st5"
                              d="M459.4,201.7l2-3.2l0.7,2.6l3.8-4.2l3.8-1.3l3,5.7l7.7-1.8l3.7,4.6l5.2-3.7l9.1,8.1l0,0l-2.7,4.1l2,11.4l0,0l-1.6,1.5l-4.5-2.6l-0.5,2.7l-2.8-0.5l-1,3.3l-2.4-1.3l-1.2,1.5l-2.5-2.2l-3.2,1l-3.9,5.7l-1.5-1.1l-1.5,2.6l-2.1-0.4l-0.2,2.2l-5.6,2.2l-2.1-1.2l-1.2,2.1l-8.2,2.8l0,0l-5.1,0.7l-2.4-3.1l0,0l-2.1-0.8l1.3-4.4l-2.8-0.2l0.3-3.5l-3-0.9l5-3.8l-0.4-4.9l-2.1-1.5l-2.3,1.6l0,0l1.6-4.3l4.8,0.1l1.7-2.1l1.2,2.2l3.9-0.8l0-8.4l2.1,0.1l0.8-2.4l2.8,0.4L459.4,201.7z"/>
                        <path id="FR-71" class="st5"
                              d="M389.9,249l2.6,2.9l3,0.4l0.3,2.3l2-1.2l1,2.7l5.3,1l-0.3,1.7l5.8,5.6l8.6-4l6.4-0.9l2,2.2l6.6-2.1l0,0l-0.2,2.2l3.2,3.4l2.9-0.3l2.6,2.3l0.1,1.6l-5.7,0.7l2.7,2.7l-1.3,2.5l3.4,5.5l-1.5,3.4l-1.8,0.2l-0.1,3.5l2.6,1L440,290l-4.3,1.8l0,0l-3.3-0.7l-2.8-3.7l-4.6,2.1l-4.8-2.1l-6.7,20.5l0,0l-2.2-0.2l0.4-3.2l-2.4-1.5l1.3-1.2l-1.3-1.6l-2.6,2.4l-2.4-1.8l-2.4,1.6l-3.2-2.1l-1.6,4.9l0,0l-0.7,2.1l-4,1.7l-2.8-2.3l-8.9,1.3l-0.9-1.8l-3.4-0.7l0.4-3.7l0,0l4.4-3.1l-0.2-8.1l-1.9-1.8l-3.6,0.2l-1.1-2.6l-4-0.4l-4.7-12.7l0,0l4.5-0.2l2.5,3.1l10.7-4.6l-0.5-3.6l2.3-1.8l-2.6-2.6l0-4.6h-1.9l2.6-1.7l-0.3-6l2.8-0.7l0,0l2.5,0.7l0.4-2.3l0,0L389.9,249z"/>
                        <path id="FR-88" class="st5"
                              d="M510.6,165.2l-1.8,10.1l4.3,0.8l0.8,1.8l0,0l-5.8,10.3l0.7,2.2l-5.5,6.8l-2,6.7l1,2.5l-3.5,1.7l0,0l-0.5,0.4l0,0l-9.1-8.1l-5.2,3.7l-3.7-4.6l-7.7,1.8l-3-5.7l-3.8,1.3l-3.8,4.2l-0.7-2.6l-2,3.2l0,0l-1.6-3.1l-2.5,1.4l-0.4-5l-6.2-3.2l1.8-1.4l0.4-4.4l2.1-0.9l-8.6-9.6l-4.1,1l0.6-3l-2-0.7l3.1-1.8l0,0l6.3-0.3l0.9-2.9l3.2,0.8l1.9-2.3l0,0l5.8,0.1l-2,5.3l3.1-1.5l-1.1,2.1l1.9,0.3l0.5,2.9l4.5-0.6l0.7-1.5l1.2,1.9l1.4-3.4l3.4,0.7l2.6-2.3l3.9,2.7l6-2.5l1.9,1l-0.5-1.6l2.2-2l1,2.8l5.7,2.5L510.6,165.2z"/>
                        <path id="FR-89" class="st5"
                              d="M337,187l4.6-5.4l-1.3-3.4l1.3-3.4l15.2-1.9l0,0l0.6,2l3.1-0.5l4.8,6.1l-1.7,5.2l2.1-0.3l1.5,3.1l3.4-1.6l-0.4,2.1l2.1,0.9l3.4,6.4l-0.8,2l1.8-1.2l0.8,1.4l-0.8,3.1l6.2,0.2l0.6-1.3l2.1,1.2l2.4-2.1l1.5,1.1l1-1.9l0.8,2.6l2,0.3l0,0l0.8,1.2l-2.7,1.9l1,2.1l2.5-0.5l0.1,5.6l-3.9,2l1.9,2.6l-2.4,0.2l0.9,1.2L386,227l0.3,4.1l-2.4,2.5l1.7,4.1l0,0l-4.3,1.8l-2.2-5.1l-3.2,1.8l-0.2-4.2l-2,3.4l-1.3-1.6l-2.5,0.4l-2.1-3.2l-3.6-0.4l-3.7-6.4l-0.5,4.1l-4-0.7l-1.9,2.2l-7.6-3l-2.6-4.2l-3.4,1.9l-1.9-0.8l0,0l-2.1-6.9l-3.2-2.2l0.2-2.5l7.2-1.8l-0.7-7.4l4.9-4.1l-0.1-3.5l-3.1-2.7l-0.8-3.7L337,187z"/>
                        <path id="FR-90" class="st5"
                              d="M498.9,208.2l1.2,2.4l7.1,3.7l-1.3,7.4l3.3,0.6l1.9,5.5l0,0l-6.2,0.6l0.8,2.5l-2.6,1.3l0,0l-1.4-2.5l1.5-2.5l-1.8-2.2l-3.9-0.8l0,0l-2-11.4l2.7-4.1l0,0L498.9,208.2z"/>
                        <text transform="matrix(1 0 0 1 420 192)" class="map-admin__zone-label">Est</text>

                    </g>
                </a>
                <a href="#" class="map-admin__zone" :class="{ active:  currentZone && currentZone.id === String(7)}" @mouseover="setCurrentZoneById(7)" @mouseout="setCurrentZoneById()" @click.prevent="goToAnchor(currentZone.link)">
                    <g>
                        <path id="FR-75" class="st6"
                              d="M311.5,141l2.9,0l0.8,2.6l0,0l1.9,2.3l-5.3,0.1l0,0l-4.4-2.2L311.5,141z"/>
                        <path id="FR-77" class="st6"
                              d="M342.7,127.3l3.8,1.1l0.1,5.5l4,4.6l1.7-0.6l1,2.6l1.6-1.3l0.6,3.5l3.2,2.2l1-0.9l0,0l0.1,2.4l-3.5,0.3l1.7,1.2l-2,2l3.1,1.3l-0.7,6.4l3.5-0.7l1,1.9l0,0l-3.9,2.9l0.7,1.8l-3.1,0.9l1.3,2.3l-2.3,0.7l1.3,5.6l0,0l-15.2,1.9l-1.3,3.4l1.3,3.4L337,187l0,0l-4.9,2.1l-0.9-2.4l-2.6,0.1l0.7,1.3l-2,1.4l-10.9,0l3.3-4.7l-0.7-2.4l-3.4-1.3l-0.8-3.5l0,0l2.9-4.6l3-0.7l-1.8-1.8l-0.2-7.2l3-9.1l0,0l1.7-4.4l-0.8-2.7l0,0l-1.5-5.1l1.8-3L321,134l0,0l1.6-4.3l0,0l1.8-1.8l2.6,2.8l2.3,0.2l2.3-2.2l1.8,1.7l4.9-1.3l0.8,1.3l3.3-0.9L342.7,127.3z"/>
                        <path id="FR-78" class="st6"
                              d="M277.5,138.5l0.7-2.4l-2,0.2l-0.9-4.9l1.7,0.2l0.9-2.2l4.1,0.5l0,0l4.8,2.1l4.4-2l1.9,3.7l1.3-1.9l4.8,3.1l4-1.2l3.5,4.2l-0.2,2.7l0,0l-2.4,4.1l2.1,4.8l0,0l-3.8,1.8l-0.2,2.6l-3.5,2.3l2.1,2.7l-1.9,3.7l-3.4-0.2l1.8,2.2l-2.3,4.2l0,0l-2.2,1.1l-2.8-1.6l-0.6-5.4l-7.6-6.8l-0.2-11.1L277.5,138.5z"/>
                        <path id="FR-91" class="st6"
                              d="M306.2,149.4l4.7,1.5l0,0l4.5,1.5l4.2-1.4l2.4,3l0,0l-3,9.1l0.2,7.2l1.8,1.8l-3,0.7l-2.9,4.6l0,0l-3.1-1.1l-3.5,2.1l-1.8-2.8l-1.6,2.8l-7,0.8l0,0l-1.5-1.5l0.8-5.7l-2.1-0.3l-0.2-3.2l0,0l2.3-4.2l-1.8-2.2l3.4,0.2l1.9-3.7l-2.1-2.7l3.5-2.3l0.2-2.6L306.2,149.4z"/>
                        <path id="FR-92" class="st6"
                              d="M309.9,137.9l2.2,0.6l-0.5,2.6l0,0l-4,2.8l4.4,2.2l0,0l-1.1,4.8l0,0l-4.7-1.5l0,0l-2.1-4.8l2.4-4.1l0,0L309.9,137.9z"/>
                        <path id="FR-93" class="st6"
                              d="M321,134l2.2,4.9l-1.8,3l1.5,5.1l0,0l-2.6-3l-5-0.2l0,0l-0.8-2.6l-2.9,0l0,0l0.5-2.6l-2.2-0.6l0,0l3.5-1.5l3,1.2L321,134z"/>
                        <path id="FR-94" class="st6"
                              d="M315.2,143.7l5,0.2l2.6,3l0,0l0.8,2.7l-1.7,4.4l0,0l-2.4-3l-4.2,1.4l-4.5-1.5l0,0l1.1-4.8l0,0l5.3-0.1L315.2,143.7z"/>
                        <path id="FR-95" class="st6"
                              d="M285.9,120.1l1.5,0.5l0.1,2.7l3.9,1.1l6.9-0.7l3.3-2.2l3.7,3l2-1l0.4,1.8l3.6-2.2l7.3,5l2.6-1.1l1.5,2.8l0,0L321,134l0,0l-4.6,3.6l-3-1.2l-3.5,1.5l0,0l-3.4,2.6l0,0l0.2-2.7l-3.5-4.2l-4,1.2l-4.8-3.1l-1.3,1.9l-1.9-3.7l-4.4,2l-4.8-2.1l0,0L285.9,120.1z"/>
                        <text transform="matrix(1 0 0 1 310 156)" class="map-admin__zone-label">IDF</text>
                        <text transform="matrix(1 0 0 1 305 166)" class="map-admin__zone-label">DOM</text>
                    </g>
                </a>
            </svg>
            <!-- eslint-enable -->
        </div>
        <div class="map-admin__list-zones d-none d-sm-block">
            <div class="mb-2">Sélectionner votre secteur</div>
            <ul class="list-unstyled">
                <li
                    class="map-admin__list-item"
                    v-for="item in zones"
                    :key="item.id"
                    :class="{ active: zoneName === item.name }"
                    @mouseover="setCurrentZoneById(item.id)"
                    @mouseout="setCurrentZoneById()">
                    <a
                        class="text-500"
                        href="#"
                        @click.prevent="goToAnchor(currentZone.link)">{{ item.name }}</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
        },
        data: function () {
            return {
                zones: [
                    {
                        id: '1',
                        name: 'Nord-Ouest',
                        link: '#nord-ouest'
                    },
                    {
                        id: '7',
                        name: 'IDF DOM',
                        link: '#ile-de-france-dom'
                    },
                    {
                        id: '4',
                        name: 'Ouest',
                        link: '#ouest'
                    },
                    {
                        id: '5',
                        name: 'Massif central - Centre',
                        link: '#massif-central-centre'
                    },
                    {
                        id: '6',
                        name: 'Est',
                        link: '#est'
                    },
                    {
                        id: '2',
                        name: 'Sud-Ouest',
                        link: '#sud-ouest'
                    },
                    {
                        id: '3',
                        name: 'Sud-Est',
                        link: '#sud-est'
                    },



                ],
                currentZone: null,
                defaultMessage: '&nbsp;'
            }
        },
        methods: {

            /**
             * Définit la zone courrante par son id.
             * @param zoneId
             */
            setCurrentZoneById(zoneId) {
                if(zoneId) {
                    this.currentZone = this.zones.find( zone => zone.id === String(zoneId));
                } else {
                    this.currentZone = null;
                }
            },

            /**
             * Va à l'ancre associée
             * @param url   L'ancre cible.
             */
            goToAnchor(url) {
                this.$scrollTo(url, 1000, {
                    offset: -50,
                    easing: 'ease-in-out'
                });
            },
        },
        computed: {

            /**
             * Retourne le nom de la zone courrante.
             * @returns {string}
             */
            zoneName() {
                return this.currentZone && this.currentZone.name ? this.currentZone.name : this.defaultMessage;
            }
        }
    }
</script>

<style scoped lang="scss">

    @import '~@/assets/styles/abstracts/variables';
    @import '~@/assets/styles/abstracts/bootstrap-vars';
    @import '~@/assets/styles/base/fonts';


    // Le composant 'map-admin'
    .map-admin {

        $colorNordOuest: #eeeea9;
        $colorSudOuest: #a9c4e3;
        $colorSudEst: #ccd8ae;
        $colorOuest: #e2ddd3;
        $colorMassifCentral: #f9c9b5;
        $colorEst: #d3bead;
        $colorIdf: #d9effd;

        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        // Le conteneur de la carte.
        &__map-wrapper {
            position: relative;
            flex: 1;
        }

        // La carte
        &__map {
            width: 100%;
            height: auto;
            display: block;
        }



        // Une zone
        &__zone {

            // Fix Firefox dotted outlines on links
            &, &:visited, &:focus, &:active, &:hover{
                outline:0 none !important;
            }

            > g {
                pointer-events: visible;
                > path {
                    cursor: pointer;
                    fill: #0D99F2;
                    stroke: #f9f9f9;
                    stroke-width: 1px;
                    opacity: 1;
                    transition: opacity 0.3s;
                }
                > text {
                    pointer-events: none;
                    &:hover {
                        fill: red;
                    }
                }

            }

            // Une zone active.
            &.active {
                > g {
                    > path {
                        opacity: 0.5;
                    }
                }
            }
        }

        // Les path
        .st0 {
            fill: $colorNordOuest;
        }
        .st1 {
            fill: $colorSudOuest;
        }
        .st2 {
            fill: $colorSudEst;
        }
        .st3 {
            fill: $colorOuest;
        }
        .st4 {
            fill: $colorMassifCentral;
        }
        .st5 {
            fill: $colorEst;
        }
        .st6 {
            fill: $colorIdf;
        }

        // Le label d'une zone.
        &__zone-label {
            @extend %font-OpenSansSemiBold;
            text-transform: uppercase;
            &:hover {
                //text-decoration:
            }
        }

        // La liste des zones.
        &__list-zones {
            align-self: center;
        }

        // Un élément de la liste.
        &__list-item {
            &.active {
                > a {
                    text-decoration: underline;
                }
            }
        }
    }

</style>











