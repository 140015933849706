module.exports = {
    bind(el, binding, vnode, oldVnode) {
        el._keyupHandler = (event) => {
            if (event.key === binding.arg) {
                binding.value();
            }
        };
        document.addEventListener('keyup', el._keyupHandler);
    },

    unbind(el, binding, vnode, oldVnode) {
        document.removeEventListener('keyup', el._keyupHandler);
    },
}
