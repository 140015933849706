<template>
    <div class="homepage-cards-carousel d-flex col-12 col-md-8">
        <vue-glide
            ref="slider"
            :breakpoints="breakpoints"
            :options="options"
            v-model="currentSlideId"
        >
            <slot></slot>
            <template #control
            >
                <button
                    data-glide-dir="<"
                    class="position-absolute arrow-button__left"
                    style="width: 48px; height: 48px;"
                >
                </button>
                <button
                    data-glide-dir=">"
                    class="position-absolute arrow-button__right"
                    style="width: 48px; height: 48px;"
                >
                </button>
            </template>
        </vue-glide>
    </div>

</template>

<script>
import 'vue-glide-js/dist/vue-glide.css'
import { Glide, GlideSlide } from 'vue-glide-js'

export default {
    name: "HomepageCardsCarousel",

    components: {
        [Glide.name]: Glide,
    },

    data(){
        return {
            currentSlideId: 0,
            breakpoints: {
                1199: {
                    perView: 1.5,
                },
                991: {
                    perView: 1,
                    gap: 8,
                },
            },
            options: {
                perView: 2,
                gap: 24,
            },
        }
    },
}
</script>

<style scoped lang="scss">
$light-grey: #CCCCCC;
$blue: #2A609D;
.arrow-button {
    font-size: 0;
    margin: 0 auto;

    &__right {
        z-index: 10;
        width: 16px;
        background-image: url('~@/static/images/icons/arrow-right_grey.svg');
        background-color: $light-grey;
        border-radius: 100%;
        top: 60%;
        left: -54%;
        background-position-y: 3px;
            &:hover {
                background-image: url('~@/static/images/icons/arrow-right_white.svg');
                background-color: $blue;
            }
    }

    &__left {
        z-index: 10;
        width: 16px;
        background-image: url('~@/static/images/icons/arrow-right_grey.svg');
        background-color: $light-grey;
        border-radius: 100%;
        transform: rotate(180deg);
        top: 60%;
        left: -62%;
            &:hover {
                background-image: url('~@/static/images/icons/arrow-right_white.svg');
                background-color: $blue;
        }
    }
}

@media screen and (max-width: 1199px) {
    .arrow-button {
        &__right {
            top: 60%;
            left: -54%;
        }
        &__left {
            top: 60%;
            left: -64%;

        }
    }
}

@media screen and (max-width: 991px) {
    .arrow-button {
        &__right {
            top: 105%;
            left: 50%;
            transform: translate(270%, -50%);
        }
        &__left {
            top: 105%;
            left: 50%;
            transform: translate(120%, -50%) rotate(180deg);
        }
    }
}

@media screen and (max-width: 768px) {
    .arrow-button {
        &__right {
            top: 105%;
            left: 50%;
            transform: translate(35%, -50%);
        }
        &__left {
            top: 105%;
            left: 50%;
            transform: translate(-120%, -50%) rotate(180deg);
        }
    }
}

.homepage-cards-carousel {
    padding: 0;
    margin: 0;
    &::v-deep {
        .glide__track {
            overflow: visible;
        }

        .glide__slide {
            height: auto;
        }
    }
}
</style>
