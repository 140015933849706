<template>
    <transition name="fade" mode="out-in">
        <slot></slot>
    </transition>
</template>

<script>
export default {
    name: "Fade"
}
</script>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
    transition: opacity 400ms ease;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>
