// Main styles
import '@/assets/styles/main.scss';

// Dependencies
import Vue from 'vue';
import VueTheMask from 'vue-the-mask';
import VModal from 'vue-js-modal';
import VueScrollTo from 'vue-scrollto';
import BackToTop from 'vue-backtotop';
import { Carousel, Slide } from 'vue-carousel';

// Components
import PostalCodeComponent from '@/app/components/PostalCodeComponent';
import AttachmentsUpload from '@/app/components/AttachmentsUpload';
import SuperSubmitButton from '@/app/components/SuperSubmitButton';
import ProfilVersements from '@/app/components/ProfilVersements';
import SituationFamiliale from '@/app/components/SituationFamiliale';
import ActivationPasswordForm from '@/app/components/ActivationPasswordForm';
import SingleTapButton from '@/app/components/SingleTapButton';
import MapAdmin from '@/app/components/MapAdmin';
import HeaderNav from '@/app/components/HeaderNav';
import HomepageCarousel from "@/app/components/HomepageCarousel";
import HomepageCardsCarousel from "@/app/components/HomepageCardsCarousel";
import HomepageFrequentSteps from "@/app/components/HomepageFrequentSteps";
import GlobalMessage from "@/app/components/GlobalMessage";
import PasswordField from "@/app/components/PasswordField";
import ResetPasswordModal from "@/app/components/ResetPasswordModal";
import DemandeAttachmentsForm from "@/app/components/DemandeAttachmentsForm.vue";
import ContactForm from "@/app/components/ContactForm.vue";

// Vue.js event bus
import { EventBus } from './event-bus.js';

// Imports Bootstrap.
import 'bootstrap/js/dist/index';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import {GlideSlide} from "vue-glide-js";



// injecte le public path au runtime car pas connu au build. __webpack_public_path__
/* eslint-disable */
if (window.runtime_webpack_public_path) {
    __webpack_public_path__ =  window.runtime_webpack_public_path;
}
/* eslint-enable */

// Vue.js config.
Vue.options.delimiters = ['||', '||'];
Vue.use(VueTheMask);
Vue.use(VModal);
Vue.use(VueScrollTo);
Vue.use(BackToTop);

// Event Bus
Vue.prototype.$eventBus = EventBus;

// Instanciate the vue
new Vue({
    el: '#app',
    components: {
        PostalCodeComponent,
        SuperSubmitButton,
        ProfilVersements,
        SituationFamiliale,
        ActivationPasswordForm,
        MapAdmin,
        Carousel,
        Slide,
        SingleTapButton,
        HeaderNav,
        HomepageCarousel,
        HomepageCardsCarousel,
        HomepageFrequentSteps,
        GlobalMessage,
        VueGlideSlide: GlideSlide,
        AttachmentsUpload,
        PasswordField,
        ResetPasswordModal,
        DemandeAttachmentsForm,
        ContactForm,
    },
});



