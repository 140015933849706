<template>
    <form @mousemove.once="onMousemove"
          @touch.once="onTouch"
          @focusin.once="onFocus"
          @submit="onSubmit"
    >
        <slot />
    </form>
</template>

<script>
export default {
    name: "ContactForm",

    data() {
        return {
            hasMoved: false,
            hasTouched: false,
            hasFocused: false,
            hasScrolled: false,
            spambotScoreField: null,
        };
    },

    mounted() {
        setTimeout(() => {
            // Hide the honeypot field
            const honeypotField = this.$el.querySelector('input#url');
            honeypotField.required = false;
            honeypotField.autocomplete = 'off';
            honeypotField.ariaHidden = 'true';
            honeypotField.previousElementSibling.textContent = 'Merci de laisser ce champ vide'
            honeypotField.parentElement.classList.add('sr-only');
        }, 250);

        this.spambotScoreField = this.$el.querySelector('input[name="message[timestamp]"]');

        document.addEventListener('scroll', this.onScroll);
    },

    methods: {
        onMousemove() {
            this.hasMoved = true;
        },

        onTouch() {
            this.hasTouched = true;
        },

        onFocus() {
            this.hasFocused = true;
        },

        onScroll() {
            document.removeEventListener('scroll', this.onScroll);

            this.hasScrolled = true;
        },

        onSubmit() {
            this.spambotScoreField.value = [
                this.hasMoved ? 'M' : 'm',
                this.hasTouched ? 'T' : 't',
                this.hasFocused ? 'F' : 'f',
                this.hasScrolled ? 'S' : 's',
            ].join('');
        },
    },
}
</script>
